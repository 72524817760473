import React from 'react'
import PropTypes from 'prop-types'
import StyledButton from 'src/assets/theme/buttons'
import { PRIMARY, WHITE } from 'src/assets/theme/colors'
import { ButtonGroup, ButtonContainer } from '../styles'

const UserLoginOrSignUp = ({ onLoginClick, onSignUpClick }) => (
  <ButtonGroup>
    <ButtonContainer onClick={onLoginClick}>
      <StyledButton color={WHITE} fontWeight="500" padding="5px 0">
        Log In
      </StyledButton>
    </ButtonContainer>
    <ButtonContainer borderAnimated onClick={onSignUpClick}>
      <StyledButton
        fontSize="16px"
        fontWeight={500}
        color={WHITE}
        opacity="0.9"
        borderColor={PRIMARY}
        letterSpacing="-0.1px"
        padding="7px 10px"
        height="40px"
        borderAnimated>
        Sign Up
      </StyledButton>
    </ButtonContainer>
  </ButtonGroup>
)

UserLoginOrSignUp.propTypes = {
  onLoginClick: PropTypes.func,
  onSignUpClick: PropTypes.func,
}

export default UserLoginOrSignUp
