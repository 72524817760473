const uploadActions = {
  uploadReset: [],
  uploadStart: ['gameId'],
  uploadError: ['error'],
  uploadSetUploading: ['uploading'],
  uploadReady: [],
  uploadVideoAdd: ['video'],
  uploadVideoRemove: ['id'],
  uploadVideoRemoveAll: [],
  uploadButtonPress: ['game', 'isExternal', 'getLocationString'],
  uploadCancelButtonPress: [],
  uploadServerVideoAdd: ['clientVideoID', 'serverVideo'],
  uploadServerVideoRemove: ['id'],
  uploadTestStartSaga: ['saga'],
  uploadSetVideoProgress: ['id', 'progress'],
  uploadSetVideoError: ['id', 'error'],
  uploadDialogResult: ['done'],
  uploadSetCompleteDialog: ['showCompleteDialog'],
}

export default uploadActions
