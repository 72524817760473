import { useState, useEffect, useRef } from 'react'

export function useMatchMedia(query, fallbackMatches = true) {
  const [mql, setMql] = useState(() => window.matchMedia?.(query))
  const [matches, setMatches] = useState(mql ? mql.matches : fallbackMatches)

  const currentQuery = useRef(query)
  useEffect(() => {
    // Allow `query` argument to change and get a new `mql`
    if (currentQuery.current !== query) {
      currentQuery.current = query

      const newMql = window.matchMedia?.(query)
      if (newMql) {
        setMql(newMql)
        setMatches(newMql.matches)
      }
    }
  }, [query])

  useEffect(() => {
    if (!mql) {
      // Fallback for browsers that don't support it
      return
    }

    const update = () => {
      setMatches(mql.matches)
    }

    if (mql.addEventListener) {
      mql.addEventListener('change', update)
    } else {
      // Safari <14 doesn't implement addEventListener here
      mql.removeListener(update)
    }

    return () => {
      if (mql.removeEventListener) {
        mql.removeEventListener('change', update)
      } else {
        mql.removeListener(update)
      }
    }
  }, [mql])

  return matches
}
