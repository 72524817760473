import { createReducer } from 'reduxsauce'
import { Types } from 'src/redux/actions'

export const INITIAL_STATE = {
  user: null,
  profile: null,
  fetching: true,
  error: undefined,
}

export const getUserProfileRequest = (
  state = INITIAL_STATE,
  { options = {} }
) => {
  let user = null
  let profile = null
  if (options.isRefresh) {
    user = state.user
    profile = state.profile
  }
  return Object.assign({}, state, { fetching: true, user, profile })
}

export const getUserProfileSuccess = (
  state = INITIAL_STATE,
  { user, profile }
) => Object.assign({}, state, { fetching: false, user, profile })

export const getUserProfileFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, { fetching: false, error })

export const createProfileRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {})

export const createProfileSuccess = (
  state = INITIAL_STATE,
  { user, profile }
) => Object.assign({}, state, { user, profile })

export const createProfileFailure = (state = INITIAL_STATE, { error: _ }) =>
  Object.assign({}, state, {})

const HANDLERS = {
  [Types.GET_USER_PROFILE_REQUEST]: getUserProfileRequest,
  [Types.GET_USER_PROFILE_SUCCESS]: getUserProfileSuccess,
  [Types.GET_USER_PROFILE_FAILURE]: getUserProfileFailure,
  [Types.CREATE_PROFILE_REQUEST]: createProfileRequest,
  [Types.CREATE_PROFILE_SUCCESS]: createProfileSuccess,
  [Types.CREATE_PROFILE_FAILURE]: createProfileFailure,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
