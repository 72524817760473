import createSagaMiddleware from 'redux-saga'
import sagas from 'src/redux/sagas'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { reducers } from 'src/redux/reducers'

export function configureStore() {
  const reducer = combineReducers(reducers)
  const sagaMiddleware = createSagaMiddleware()

  const composeEnhancers = compose(
    applyMiddleware(sagaMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )

  const store = createStore(reducer, composeEnhancers)

  sagaMiddleware.run(sagas)
  return store
}
