import React from 'react'
import { Text } from 'src/assets/theme/text'
import { WHITE } from 'src/assets/theme/colors'

export default () => (
  <p
    style={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 0,
      backgroundColor: WHITE,
    }}>
    <Text style={{ textAlign: 'center' }}>Loading...</Text>
  </p>
)
