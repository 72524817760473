import { createTheme } from '@mui/material/styles'

import { DARK_BLUE, ACCENTS } from 'src/assets/theme/colors'

const customBreakpoints = {
  xs: 0,
  sm: 650,
  md: 960,
  lg: 1280,
  xl: 2000,
}
export const customTheme = createTheme({
  breakpoints: { values: customBreakpoints },
  spacing: new Array(30).fill(null).map((_, index) => index * 8),
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'],
    // https://stackoverflow.com/a/62950304/7065976
    allVariants: {
      color: DARK_BLUE,
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: DARK_BLUE + ' !important',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            opacity: 1,
            WebkitTextFillColor: DARK_BLUE,
          },
        },
        underline: {
          color: DARK_BLUE,
          '&:before': {
            borderBottomColor: DARK_BLUE,
          },
          '&:after': {
            borderBottomColor: ACCENTS,
          },
          '&:hover:not(.Mui-disabled):before': {
            borderColor: DARK_BLUE,
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            opacity: 1,
            WebkitTextFillColor: DARK_BLUE,
          },
        },
        icon: {
          fill: '#313131',
          transform: 'scale(1.8)',
        },
        iconOpen: {
          transform: 'rotate(180deg) scale(1.8)',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: '0 !important',
          paddingBottom: '0 !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: '38px !important',
        },
      },
    },
  },
})
