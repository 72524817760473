/** Actions related to user Auth and Auth Service */

const auth = {
  authSuccess: ['isAuthenticated', 'isVerified'],
  authFailure: ['error'],
  setConfirmingEmail: ['confirmingEmail'],
  signInWithEmailRequest: ['email', 'password'],
  signInFailure: ['error'],
  signUpWithEmailRequest: ['user'],
  signUpSuccess: [],
  signUpFailure: ['error'],
  logout: [],
}

export default auth
