import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { TextNormal, TextError, TextLink } from 'src/assets/theme/text'
import FrameWithSideBar from 'src/components/FrameWithSideBar'
import { auth } from 'src/components/App'
import { useRouter } from 'src/hooks'
import { ReactComponent as DarkLogo } from 'src/assets/img/tpeLogos/tpeLogo_White.svg'
import DotsLoader from '../DotsLoader'
import {
  LogOutButton,
  BackButton,
  ErrorBox,
  ErrorBoxWrapper,
  ErrorMessage,
  ErrorTitle,
  FatalErrorStyles,
  LogoContainer,
  JustRawWrapper,
  JustRawStyles,
} from './styles'

const selectHasTriedLoggingIn = (state) =>
  Boolean(state.user.user || state.user.error)

const addCommas = (items) =>
  items
    .filter((item) => item != null)
    .flatMap((item, i) => {
      const isLast = i === items.length - 1

      if (isLast) {
        return [', or ', item]
      }

      return [', ', item]
    })

const FrameSystemMessage = ({
  children,
  error = false,
  raw = false,
  textProps = {},
  title,
}) => {
  const { history } = useRouter()
  const hasUser = useSelector(selectHasTriedLoggingIn)
  const TextComponent = error ? TextError : TextNormal

  const Wrapper = raw ? JustRawWrapper : FrameWithSideBar
  if (error && raw) {
    return (
      <ErrorBoxWrapper>
        <FatalErrorStyles />
        <ErrorBox>
          <LogoContainer>
            <DarkLogo />
          </LogoContainer>
          <ErrorTitle>{title}</ErrorTitle>
          <ErrorMessage>{children}</ErrorMessage>
          <BackButton
            onClick={(event) => {
              event.preventDefault()
              history.goBack()
            }}
          >
            Go Back
          </BackButton>
          <LogOutButton
            onClick={() => {
              auth.logout()
            }}
          >
            Log Out
          </LogOutButton>
        </ErrorBox>
      </ErrorBoxWrapper>
    )
  }
  if (!error && raw) {
    return (
      <Wrapper>
        <JustRawStyles />
        <DotsLoader
          title={title}
          loaderTextSize="18px"
          loaderTextWeight={500}
          dotsFontSize="32px"
          dotsFontWeight={900}
        />
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <div style={{ paddingTop: '5em', textAlign: 'center' }}>
        <TextComponent style={{ textAlign: 'center' }} {...textProps}>
          {children}
        </TextComponent>
        {error && (
          <TextNormal style={{ textAlign: 'center' }}>
            Please{' '}
            {addCommas([
              <TextLink
                key="go-back"
                inline
                onClick={(event) => {
                  event.preventDefault()
                  history.goBack()
                }}
              >
                Go Back
              </TextLink>,
              hasUser ? (
                <TextLink
                  key="log-out"
                  inline
                  onClick={() => {
                    auth.logout()
                  }}
                >
                  Log out
                </TextLink>
              ) : null,
              'try again.',
            ])}
          </TextNormal>
        )}
      </div>
    </Wrapper>
  )
}

FrameSystemMessage.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  raw: PropTypes.bool,
  textProps: PropTypes.object,
  title: PropTypes.string,
}

export default FrameSystemMessage
