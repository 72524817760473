import { ACCENTS } from 'src/assets/theme/colors'
import styled, { css } from 'styled-components/macro'

export const SearchBoxContainer = styled.div`
  width: 100%;
  margin: ${(props) => props.margin || 'auto'};

  ${(props) =>
    props.darkMode &&
    css`
      background: white;
      overflow: hidden;
      border-radius: 2px;
      .MuiInput-underline {
        padding: 3px 32px 3px 12px;
        &:before,
        &:after {
          display: none;
        }
      }
    `}
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 4px;
  right: 6px;
  margin: auto;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: ${ACCENTS};
  cursor: pointer;
`
