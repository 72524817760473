import { take } from 'redux-saga/effects'
import { Types } from 'src/redux/actions'
import { errorHandler } from 'src/services/ErrorReporter'

export function* errorReporting() {
  while (true) {
    const { user } = yield take(Types.GET_USER_PROFILE_SUCCESS)
    errorHandler.setUser(user.email)
  }
}
