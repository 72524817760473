/** Actions related to the User's account */

const user = {
  getUserProfileRequest: ['options'],
  getUserProfileSuccess: ['user', 'profile'],
  getUserProfileFailure: ['error'],
  createProfileRequest: ['profile'],
  createProfileSuccess: ['user', 'profile'],
  createProfileFailure: ['error'],
}

export default user
