import { ACCENTS, DARK_BLUE } from 'src/assets/theme/colors'
import styled from 'styled-components/macro'

export const GridSpacerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: '1 0 auto';
  flex: 1;
`

export const Spacer = styled.div`
  display: none;
  @media (min-width: 1280px) {
    display: block;
    width: 300px;
    opacity: 0;
    z-index: -100;
  }
`

export const TopScroll = styled.div`
  height: 35px;
  width: 35px;
  position: fixed;
  bottom: 5px;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${ACCENTS};
  border-radius: 50%;
  cursor: pointer;
  opacity: ${(props) => (props.showScrollTop ? 1 : 0)};
  transition: opacity 0.16s ease-in-out;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.12);
  z-index: 500;
  svg {
    transform: rotate(-90deg);
    margin: 0 0 3px 0;
    fill: ${DARK_BLUE};
  }
  /*  Depends on DashboardView and MUI paddings */
  @media (min-width: 700px) {
    margin: 0 0 0 8px;
    bottom: 20px;
  }
  @media (min-width: 1280px) {
    right: unset;
    margin: 0 0 0 308px;
  }
  @media (min-width: 1400px) {
    height: 40px;
    width: 40px;
    margin: 0 0 0 315px;
    bottom: 15px;
  }
`
