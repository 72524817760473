import styled from 'styled-components/macro'
import { withLinkStyle } from 'src/assets/theme/links'
import {
  ACCENTS,
  DARK_BLUE,
  BG_HIGHLIGHT_TWO,
  LIGHT_RED_2,
  WHITE,
} from 'src/assets/theme/colors'

const StyledButton = withLinkStyle('button')

export const Button = styled('button')`
  display: ${(props) => props.display || 'block'};
  padding: 5px 3px;
  width: 100%;
  height: 42px;

  color: ${DARK_BLUE};
  background: ${ACCENTS};
  border: 0 none;
  font-weight: 500;
  margin: 0;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  appearance: none;
  font-family: Montserrat;
`

export const MainGeneralButton = styled('button')`
  display: flex;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '42px')};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  color: ${(props) => (props.forceWhite ? `${WHITE} !important` : WHITE)};
  text-align: center;
  background-color: ${ACCENTS};
  position: relative;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 2px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`

export const CancelButton = styled(MainGeneralButton)`
  background: ${BG_HIGHLIGHT_TWO};
  color: ${LIGHT_RED_2};
  border: 1px solid ${LIGHT_RED_2};
`

export default StyledButton
