import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import { Input, InputAdornment, InputLabel, FormControl } from '@mui/material'

import searchIcon from 'src/assets/img/search-icon.svg'
import { SearchBoxContainer, IconWrapper } from './styles'

const SearchBox = (props) => {
  const [id] = useState(nanoid)

  const input = (
    <Input
      id={id}
      fullWidth
      endAdornment={
        <InputAdornment position="end">
          <IconWrapper data-test-search>
            <img src={searchIcon} onClick={() => props.onSearch()} />
          </IconWrapper>
        </InputAdornment>
      }
      padding={props.padding}
      value={props.value}
      name={props.name}
      onChange={(e) => {
        props.onChange(e)
      }}
      placeholder={props.darkMode ? props.placeholderText : null}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          props.onSearch()
        }
      }}
      type="text"
      className={props.className}
      style={{ ...props.style }}
    />
  )

  if (props.darkMode) {
    return (
      <SearchBoxContainer darkMode margin={props.margin}>
        {input}
      </SearchBoxContainer>
    )
  }

  return (
    <SearchBoxContainer margin={props.margin}>
      <FormControl fullWidth variant="standard">
        <InputLabel htmlFor={id}>{props.placeholderText}</InputLabel>
        {input}
      </FormControl>
    </SearchBoxContainer>
  )
}

SearchBox.propTypes = {
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  style: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  placeholderText: PropTypes.string,
  darkMode: PropTypes.bool,
  padding: PropTypes.string,
  margin: PropTypes.string,
}

export default SearchBox
