import styled, { createGlobalStyle } from 'styled-components'
import { MainGeneralButton } from 'src/assets/theme/buttons'
import {
  BG_CRASH,
  WHITE,
  BG_HIGHLIGHT,
  LINES,
  DARK_BLUE,
  LIGHT_RED_2,
} from 'src/assets/theme/colors'

const centerMixin = `
  display: flex;
  justify-content: center;
  align-items: center;  
`

export const JustRawStyles = createGlobalStyle`
  #root{ ${centerMixin}; }
  `

export const JustRawWrapper = styled.div`
  ${centerMixin};
`

export const FatalErrorStyles = createGlobalStyle`
  body {
    background-color:${BG_CRASH} !important;
  }
  #root{
    ${centerMixin};
  }
`

export const ErrorBoxWrapper = styled.div`
  ${centerMixin};
`

export const ErrorBox = styled.div`
  width: 300px;
  background-color: ${WHITE};
  box-shadow: 0px 3px 11px 3px rgba(0, 0, 0, 0.24);
`

export const LogoContainer = styled.div`
  ${centerMixin};
  height: 120px;
  width: 100%;
  background-color: ${BG_HIGHLIGHT};
  border-bottom: 1px solid ${LINES};
  svg {
    width: 80%;
    path {
      fill: ${DARK_BLUE};
    }
    .logoShape {
      fill: ${LIGHT_RED_2};
    }
  }
`

export const ErrorTitle = styled.h3`
  width: 260px;
  margin: 24px auto 0 auto;
  font-size: 16px;
  font-weight: 600;
  color: ${LIGHT_RED_2};
`

export const ErrorMessage = styled.p`
  width: 260px;
  margin: 24px auto;
  color: ${DARK_BLUE};
`

export const LogOutButton = styled(MainGeneralButton)`
  width: 260px;
  height: 48px;
  margin: 16px auto;
  background-color: ${LIGHT_RED_2};
`

export const BackButton = styled(LogOutButton)`
  margin: 0 auto;
  background-color: ${DARK_BLUE};
`
