import immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { Types } from 'src/redux/actions'

const initialState = immutable({
  // Object containing game information, goes straight to iceberg
  game: null,
  // Is this an external upload?
  isExternal: false,
  // A function that returns the string for IB staff to help them find the videos
  // Depends on upload type
  getLocationString: (_videoArray) => '',
  videos: [],
  // Videos which have been created in the server.
  // These are the ones which are uploaded.
  serverVideos: [],
  // Game ID from the server.
  gameId: null,
  loading: true,
  uploading: false,
  error: '',
  showCompleteDialog: false,
})

const handlers = {
  [Types.UPLOAD_RESET]: () => initialState,

  // When the upload form shows up
  [Types.UPLOAD_START]: (_, { gameId = null }) =>
    initialState.merge({ gameId }),

  [Types.UPLOAD_BUTTON_PRESS]: (
    state,
    { game, isExternal, getLocationString }
  ) =>
    state.merge({
      game,
      isExternal,
      getLocationString,
      error: '',
      videos: state.videos.map((video) => video.merge({ error: '' })),
    }),

  [Types.UPLOAD_ERROR]: (state, { error }) => state.merge({ error }),
  [Types.UPLOAD_READY]: (state) => state.merge({ loading: false }),
  [Types.UPLOAD_SET_UPLOADING]: (state, { uploading }) =>
    state.merge({ uploading }),
  [Types.UPLOAD_VIDEO_ADD]: (state, { video }) =>
    state.merge({
      videos: state.videos.concat(video),
    }),
  [Types.UPLOAD_VIDEO_REMOVE]: (state, { id }) =>
    state.merge({
      videos: state.videos.filter((v) => v.id !== id),
    }),
  [Types.UPLOAD_VIDEO_REMOVE_ALL]: (state) =>
    state.merge({
      // Keep the game sheets
      videos: state.videos.filter((v) => v.isGameSheet),
    }),
  [Types.UPLOAD_SERVER_VIDEO_ADD]: (state, { clientVideoID, serverVideo }) =>
    state.merge({
      serverVideos: state.serverVideos.concat({
        ...serverVideo,
        clientVideoID,
      }),
      videos: state.videos.map((v) =>
        v.id === clientVideoID ? v.merge({ serverVideoID: serverVideo.id }) : v
      ),
    }),
  [Types.UPLOAD_SERVER_VIDEO_REMOVE]: (state, { id }) =>
    state.merge({
      serverVideos: state.serverVideos.filter((v) => v.id !== id),
    }),
  [Types.UPLOAD_SET_VIDEO_PROGRESS]: (state, { id, progress }) =>
    state.merge({
      videos: state.videos.map((video) =>
        video.id === id ? video.merge({ progress }) : video
      ),
    }),
  [Types.UPLOAD_SET_VIDEO_ERROR]: (state, { id, error }) =>
    state.merge({
      videos: state.videos.map((video) =>
        video.id === id ? video.merge({ error }) : video
      ),
    }),
  [Types.UPLOAD_SET_COMPLETE_DIALOG]: (state, { showCompleteDialog }) =>
    state.merge({ showCompleteDialog }),
}

export default createReducer(initialState, handlers)
