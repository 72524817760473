export const BLACK = '#000000'
export const PRIMARY = '#3576E0'
export const TEXT = '#504746'
export const GRAY = '#999999'
export const LIGHT_GRAY = '#F7F7F7'
export const LIGHT_GRAY_2 = '#EDEDED'
export const LIGHT_GRAY_3 = '#979797'
export const LIGHT_GRAY_4 = '#8999A1'
export const LIGHT_GRAY_5 = '#666666'
export const LIGHT_GRAY_6 = '#F3F3F3'
export const LIGHT_GRAY_7 = '#212121'
export const GRAY_BLUE = '#8999A1'
export const GOLD = '#DFAF2B'
export const GREEN = '#31A600'
export const LIGHT_GREEN = '#628E63'
export const RED = '#E10000'
export const LIGHT_RED = '#D64933'
export const ORANGE = '#E57C04'
export const LIGHT_NAVY = '#92B6B1'
export const PALE_BLUE = '#60ADBD'
export const BOLD_BLUE = '#4285F4'

// =================================
// new UI colors:
export const DARK_BLUE = '#0E1D28'
export const DARK_BLUE_LIGHT = '#2B3942'
export const ACCENTS = '#1D7DFF'
export const LIGHT_RED_2 = '#AA000B'
export const WHITE = '#FFFFFF'
export const ONE_GRAY = '#ECECEC'
export const TWO_GRAY = '#EFEFEF'
export const LINES = 'rgba(14, 29, 40, 0.1)'
export const LINES_2 = 'rgba(14, 29, 40, 0.15)'
export const LINES_3 = 'rgba(14, 29, 40, 0.2)'
export const BG_HIGHLIGHT = '#F7F8F8'
export const BG_HIGHLIGHT_TWO = '#FAFBFB'
export const BG_LIGHT_BLUE = '#e7f1ff'
export const BG_CRASH = '#27343E'
