import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const StopScroll = ({ stopScroll = true }) => {
  return (
    <Helmet>
      <style type="text/css">
        {`
          html {
            overflow-y: ${stopScroll ? 'hidden' : 'unset'};
          }
        `}
      </style>
    </Helmet>
  )
}

export default StopScroll

StopScroll.propTypes = {
  stopScroll: PropTypes.bool,
}
