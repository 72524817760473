import { eventChannel } from 'redux-saga'

export let locationChannel

export default function createChannels({ history }) {
  locationChannel = () =>
    eventChannel((emit) => {
      const unlisten = history.listen((location) => {
        emit(location.pathname + location.search)
      })
      return unlisten
    })
}
