import React, { lazy, Suspense, useEffect } from 'react'
import { Switch, Route as PublicRoute, Redirect } from 'react-router-dom'

import querystring from 'src/services/querystring'
import { useRouter } from 'src/hooks'
import AuthorizedRoute from 'src/components/AuthorizedRoute'
import GlobalSuspenseFallback from 'src/components/GlobalSuspenseFallback'
import { canCommunicate, canUseWebsite } from 'src/services/Entitlements'
import { LocalStorage } from 'src/services/LocalStorage'

const AuthorizationCallback = lazy(() =>
  import(
    /* webpackChunkName: "view-AuthorizationCallback" */ 'src/views/AuthorizationCallback'
  )
)
const SignUp = lazy(() =>
  import(/* webpackChunkName: "view-SignUp" */ 'src/views/SignUp')
)
const Login = lazy(() =>
  import(/* webpackChunkName: "view-Login" */ 'src/views/Login')
)
const RecoverPassword = lazy(() =>
  import(
    /* webpackChunkName: "view-RecoverPassword" */ 'src/views/RecoverPassword'
  )
)
const PlayerDashboard = lazy(() =>
  import(
    /* webpackChunkName: "view-DashboardPlayer" */ 'src/views/Dashboard/Player'
  )
)
const CoachDashboard = lazy(() =>
  import(
    /* webpackChunkName: "view-DashboardPlayer" */ 'src/views/Dashboard/Coach'
  )
)
const Player = lazy(() =>
  import(/* webpackChunkName: "view-Player" */ 'src/views/Player')
)
const ConfirmEmail = lazy(() =>
  import(/* webpackChunkName: "view-ConfirmEmail" */ 'src/views/ConfirmEmail')
)
const WaitForRequestedTeam = lazy(() =>
  import(
    /* webpackChunkName: "view-WaitForRequestedTeam" */ 'src/views/WaitForRequestedTeam'
  )
)
const ProfileCreation = lazy(() =>
  import(
    /* webpackChunkName: "view-ProfileCreation" */ 'src/views/ProfileCreation'
  )
)
const EditProfile = lazy(() =>
  import(/* webpackChunkName: "view-EditProfile" */ 'src/views/EditProfile')
)
const ProfileViewsCoach = lazy(() =>
  import(
    /* webpackChunkName: "view-ProfileViews" */ 'src/views/ProfileViews/Coach'
  )
)
const ProfileViewsPlayer = lazy(() =>
  import(
    /* webpackChunkName: "view-ProfileViews" */ 'src/views/ProfileViews/Player'
  )
)
const SendToCoach = lazy(() =>
  import(/* webpackChunkName: "view-SendToCoach" */ 'src/views/SendToCoach')
)

const NewGame = lazy(() =>
  import(/* webpackChunkName: "view-NewGame" */ 'src/views/NewGame')
)

const PurchaseCredits = lazy(() =>
  import(
    /* webpackChunkName: "view-PurchaseCredits" */ 'src/views/PurchaseCredits'
  )
)
const Checkout = lazy(() =>
  import(/* webpackChunkName: "view-Checkout" */ 'src/views/Checkout')
)
const Membership = lazy(() =>
  import(/* webpackChunkName: "view-Membership" */ 'src/views/Membership')
)

const TermsAndConditions = lazy(() =>
  import(
    /* webpackChunkName: "view-TermsAndConditions" */ 'src/views/TermsAndConditions'
  )
)
const PrivacyPolicy = lazy(() =>
  import(/* webpackChunkName: "view-PrivacyPolicy" */ 'src/views/PrivacyPolicy')
)
const PageNotFound = lazy(() =>
  import(/* webpackChunkName: "view-PageNotFound" */ 'src/views/PageNotFound')
)

const suspenseFallback = <GlobalSuspenseFallback />

const alsoAllowAnonymousUsers = ({ user, profile }) => !user && !profile

function FaqRedirect() {
  useEffect(() => {
    window.location.assign('https://theprospectexchange.com/faq/')
  }, [])
}

/**
 * Routes component
 * Responsible for rendering our views, be it a public route or protected route.
 *
 * Used history.replace() instead of history.push() because the link in
 * the email takes you first to the /dashboard and then goes to /profile/create-profile,
 * so with this we stop the user from being able to go back to the /dashboard from
 * the /profile/create-profile route, which is useful if the user didn't complete the
 * profile creation
 */

/* eslint-disable react/prop-types */
export default function Routes() {
  const {
    location: { pathname },
  } = useRouter()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Suspense fallback={suspenseFallback}>
      <Switch>
        {/* Authentication */}
        <Redirect exact from="/" to="/auth/login" />
        <AuthorizedRoute
          path="/auth/callback"
          exact
          protection="none"
          component={AuthorizationCallback}
        />
        <AuthorizedRoute
          path="/auth/sign-up"
          exact
          userType="NONE"
          protection="none"
          component={SignUp}
        />
        <AuthorizedRoute
          path="/auth/claim"
          exact
          userType="NONE"
          protection="none"
          render={(props) => {
            const { claim } = querystring.parse(props.location.search.slice(1))
            return <SignUp claim={Boolean(claim)} {...props} />
          }}
        />
        <AuthorizedRoute
          path="/auth/login"
          exact
          userType="NONE"
          protection="none"
          component={Login}
        />
        <AuthorizedRoute
          path="/auth/recover-password"
          exact
          userType="NONE"
          protection="none"
          component={RecoverPassword}
        />
        <AuthorizedRoute
          path="/auth/email/confirm"
          exact
          protection="authenticated"
          component={ConfirmEmail}
        />

        {/* Profile stuff */}
        <AuthorizedRoute
          path="/profile/wait-for-requested-team"
          exact
          protection="verified"
          component={WaitForRequestedTeam}
        />
        <AuthorizedRoute
          path="/profile/create-profile"
          exact
          userType="NONE"
          protection="verified"
          component={ProfileCreation}
        />
        <AuthorizedRoute
          path="/profile/edit-profile"
          exact
          protection="verified"
          entitlement={canUseWebsite}
          component={EditProfile}
        />

        {/* Dashboards */}
        <AuthorizedRoute
          path="/dashboard-coach"
          exact
          userType="COACH"
          protection="verified"
          component={CoachDashboard}
        />
        <AuthorizedRoute
          path="/dashboard-player"
          exact
          userType="PLAYER"
          protection="verified"
          entitlement={canUseWebsite}
          component={PlayerDashboard}
        />

        {/* Creating a new game */}
        <AuthorizedRoute
          path="/new-game"
          exact
          userType="PLAYER"
          protection="verified"
          entitlement={canUseWebsite}
          component={NewGame}
        />

        {/* Paying for games */}
        {/* skipBounce used to allow users without accounts */}
        <AuthorizedRoute
          path="/purchase-credits"
          exact
          userType="PLAYER"
          protection="verified"
          skipBounce={alsoAllowAnonymousUsers}
          entitlement={canUseWebsite}
          render={(props) => (
            <PurchaseCredits
              key={'search' + props.location.search}
              {...props}
            />
          )}
        />
        <AuthorizedRoute
          path="/purchase-credits/checkout"
          exact
          userType="PLAYER"
          protection="verified"
          skipBounce={alsoAllowAnonymousUsers}
          entitlement={canUseWebsite}
          component={Checkout}
        />
        <AuthorizedRoute
          path="/membership"
          userType="PLAYER"
          protection="verified"
          component={Membership}
        />
        <AuthorizedRoute
          path="/campaign/:campaignId"
          protection="none"
          render={({ match, location }) => {
            const { couponCode } = Object.fromEntries(
              new URLSearchParams(location.search)
            )
            const campaignUrl = `/membership/${match.params.campaignId}${
              couponCode ? '?couponCode=' + encodeURIComponent(couponCode) : ''
            }`
            LocalStorage.setItem('marketingSiteRedirect', campaignUrl)
            return <Redirect to="/auth/sign-up" />
          }}
        />

        {/* Window into iceberg */}
        <AuthorizedRoute
          path="/players/:playerId"
          exact
          protection="verified"
          entitlement={canUseWebsite}
          render={({ match }) => (
            <Player key={match.params.playerId} match={match} />
          )}
        />

        {/* Who has viewed my profile */}
        <AuthorizedRoute
          path="/profile-views-coach"
          exact
          userType="COACH"
          protection="verified"
          component={ProfileViewsCoach}
        />
        <AuthorizedRoute
          path="/profile-views-player"
          exact
          userType="PLAYER"
          protection="verified"
          entitlement={canUseWebsite}
          component={ProfileViewsPlayer}
        />

        {/* Correspondence */}
        <AuthorizedRoute
          path="/send-to-coach"
          exact
          userType="PLAYER"
          protection="verified"
          entitlement={canCommunicate}
          component={SendToCoach}
        />

        {/* Misc */}
        <PublicRoute
          path="/terms-and-conditions"
          exact
          component={TermsAndConditions}
        />
        <PublicRoute path="/privacy-policy" exact component={PrivacyPolicy} />
        <PublicRoute path="/faq" exact component={FaqRedirect} />
        <AuthorizedRoute
          path="/*"
          protection="verified"
          component={PageNotFound}
        />
      </Switch>
    </Suspense>
  )
}
