/*
  Actions related to the App's teams
*/

const team = {
  getTeamNamesRequest: null,
  getTeamNamesSuccess: ['teams'],
  getTeamNamesFailure: ['error'],
  getTeamNamesFromLevelRequest: ['gender', 'level'],
  getTeamNamesFromLevelSuccess: ['teams'],
  getTeamNamesFromLevelFailure: ['error'],
  getLevelRequest: ['gender', 'isCoach'],
  getLevelSuccess: ['levels'],
  getLevelFailure: ['error'],
  getBothLevelsRequest: null,
  getBothLevelsSuccess: ['bothLevels'],
  getBothLevelsFailure: ['error'],
}

export default team
