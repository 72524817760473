import React, { useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import { validate } from 'src/utils/validation'
import hasFlag from 'src/services/Split'
import { assert } from 'src/services/assert'

// https://usehooks.com/useRouter/  (adapted)
export function useRouter() {
  const location = useLocation()
  const history = useHistory()

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return { location, history }
  }, [location, history])
}

export function useValidation({ fields, rules, showErrors = true }) {
  assert(
    fields && typeof fields === 'object',
    'useValidator fields option must be an object containing the values of all form fields'
  )
  assert(
    Array.isArray(rules) && rules.every((r) => typeof r === 'function'),
    'useValidator rules argument must be an array of rule functions'
  )

  // using useMemo to avoid calling validate over and over again unnecessarily
  return useMemo(() => {
    const ret = validate(fields, rules)
    const noErrors = isEqual(ret, {})
    if (!showErrors) return { noErrors }
    return { ...ret, noErrors }
  }, [fields, rules, showErrors])
}

export function useExperiment(experimentId) {
  const [variant, setVariant] = React.useState(0)

  const updateVariant = React.useCallback((value) => {
    const newVariant = Number(value) || 0
    setVariant(newVariant)
  }, [])

  React.useEffect(() => {
    const { dataLayer, google_optimize: optimizeGlobal } = window
    const delayedInitialization = () => {
      updateVariant(optimizeGlobal && optimizeGlobal.get(experimentId))
    }

    if (dataLayer) {
      const hideEnd = dataLayer && dataLayer.hide && dataLayer.hide.end

      if (hideEnd) {
        dataLayer.hide.end = () => {
          delayedInitialization()
          hideEnd()
        }
      } else {
        delayedInitialization()
      }

      dataLayer.push('event', 'optimize.callback', {
        name: experimentId,
        callback: updateVariant,
      })
    }

    return () => {
      dataLayer.push('event', 'optimize.callback', {
        name: experimentId,
        callback: updateVariant,
        remove: true,
      })
    }
  }, [experimentId, updateVariant])

  // Doing this instead of `return variant` avoids
  // multiple re-renders when the variant didn't change
  return React.useMemo(() => {
    const style = `color: ${variant > 0 ? 'red' : 'unset'}`
    if (hasFlag('analyticslog')) {
      console.log(
        '%c🅰🅱 variant %d, experiment %s',
        style,
        variant,
        experimentId
      )
    }
    return variant
  }, [variant, experimentId])
}
