export const canCommunicate = (user, profile) => {
  if (user?.userType === 'COACH') return true

  return profile?.subscriptionStatus === 'ACTIVE'
}

export const canUseWebsite = (user, profile) => {
  if (user?.userType === 'COACH') return true

  // for new-player-landing Cypress test
  if (profile?.id === 14082) return false

  return Boolean(
    profile?.subscriptionStatus === 'ACTIVE' || profile?.icebergPlayerId
  )
}
