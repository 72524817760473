import styled, { css } from 'styled-components/macro'
import { WHITE, DARK_BLUE, LINES } from 'src/assets/theme/colors'
import {
  borderLoadAnimationWidth,
  borderLoadAnimationHeight,
} from 'src/assets/theme/animations'

export const HeaderContainer = styled.div`
  height: 60px;
  background-color: ${DARK_BLUE};
  display: flex;
  padding: 0 15px;
  transition: 0.3s ease;
  @media (min-width: 650px) {
    height: 80px;
  }
  @media (min-width: 700px) {
    padding: 0 50px;
  }
`

export const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 38px;
  svg {
    height: 17px;
    width: 17px;
    path {
      fill: ${DARK_BLUE} !important;
    }
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const ButtonContainer = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '10px')};
  &:nth-child(1) {
    button {
      &:before {
        background-color: ${WHITE};
      }
    }
  }
  &:nth-child(2) {
    button {
      &:before {
        background-color: ${WHITE};
      }
      &:after {
        background-color: ${WHITE};
      }
      &:hover {
        background-color: ${WHITE};
        color: ${DARK_BLUE};
      }
    }
  }
  ${(props) =>
    props.borderAnimated
      ? css`
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: ${WHITE};
            height: 1px;
            animation: ${borderLoadAnimationWidth} 0.3s 0.6s linear forwards;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: ${WHITE};
            width: 1px;
            animation: ${borderLoadAnimationHeight} 0.3s 0.9s linear forwards;
          }
        `
      : ``};
`

export const HamburgerBtn = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${WHITE};
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 50%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${WHITE};
    &:nth-child(1) {
      transform: translateY(6px);
    }
    &:nth-child(3) {
      transform: translateY(-6px);
    }
  }
  @media (min-width: 640px) {
    width: 50px;
    height: 50px;
    span {
      &:nth-child(1) {
        transform: translateY(8px);
      }
      &:nth-child(3) {
        transform: translateY(-8px);
      }
    }
  }
`

export const MenuListContainer = styled.div`
  display: flex;
  .leftColumn {
    border-right: 1px solid ${LINES};
  }
`

export const MobileMenu = styled.div`
  width: 100%;
  height: calc(100vh - 60px) !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  top: 60px;
  background-color: ${DARK_BLUE};
  transform: ${(props) =>
    props.isOpen ? 'translateY(0)' : 'translateY(-100vh)'};
  transition: transform 0.2s ease-in-out;
  z-index: -1;
`

export const PlayerMenu = styled.div`
  display: ${(props) => (props.isPlayer ? 'flex' : 'none')};
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 60px 10px;

  & > * + * {
    margin-top: 40px !important;
  }
  @media (min-height: 500px) and (orientation: portrait) {
    padding: 60px 20px;
  }
  @media (min-height: 600px) and (orientation: portrait) {
    display: ${(props) => (props.isPlayer ? 'block' : 'none')};
  }
  @media (min-height: 700px) and (orientation: portrait) {
    padding: 124px 20px 95px 20px;
  }
  @media (orientation: landscape) {
    padding: 20px;
  }
`

export const CoachMenu = styled(PlayerMenu)`
  display: ${(props) => (props.isCoach ? 'flex' : 'none')};
  @media (min-height: 600px) and (orientation: portrait) {
    display: ${(props) => (props.isCoach ? 'block' : 'none')};
  }
`

export const SearchBoxContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 0 0 20px 0;
  > input {
    width: 95% !important;
    height: 40px !important;
    font-size: 16px !important;
  }
  @media (min-height: 650px) and (orientation: portrait) {
    margin: 0 0 40px 0;
    > input {
      width: 100% !important;
      height: 50px !important;
      font-size: 18px !important;
    }
  }
  @media (orientation: landscape) {
    margin: 0;
  }
`

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 150px;
  margin: 0 auto 30px auto;
  img {
    height: 32px;
    width: 32px;
    padding: 6px;
    border: 2px solid ${WHITE};
    border-radius: 50%;
  }
  @media (min-height: 600px) and (orientation: portrait) {
    margin: 0 auto;
  }
  @media (min-height: 650px) and (orientation: portrait) {
    width: 180px;
    img {
      height: 40px;
      width: 40px;
    }
  }
  @media (orientation: landscape) {
    margin: 0 0 10px 0;
  }
`

export const MobileMenuLink = styled.p`
  width: 190px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-family: Montserrat;
  color: ${WHITE};
  text-align: center;
  cursor: pointer;
  margin: 0 auto;

  @media (min-height: 500px) and (orientation: portrait) {
    height: 40px;
  }
  @media (min-height: 650px) and (orientation: portrait) {
    font-size: 22px;
  }
`

export const LogOutBtn = styled(MobileMenuLink)`
  width: 150px;
  margin-bottom: 0;
  border: 1px solid ${WHITE};
  position: ${(props) => (props.hasNoProfile ? 'absolute' : 'static')};
  bottom: ${(props) => (props.hasNoProfile ? '95px' : 'auto')};
  @media (min-height: 600px) and (orientation: portrait) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 95px;
    margin: auto;
    width: 180px;
  }
`
