export const insertSpaces = (string) => {
  string = string.replace(/([a-z])([A-Z])/g, '$1 $2')
  string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  return string
}

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const positions = [
  { value: 'DEFENSE', name: 'Defense', shortName: 'D' },
  { value: 'FORWARD', name: 'Forward', shortName: 'F' },
  { value: 'GOALTENDER', name: 'Goalie', shortName: 'G' },
]

export const positionToString = (position) =>
  positions.find((p) => p.value === position)?.name

export const positionToShortString = (position) =>
  positions.find((p) => p.value === position)?.shortName
