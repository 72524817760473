import { format, parseISO } from 'date-fns'

/**
 * Format a date unless it's a default-ish date (1900, 1800,...)
 */
export function formatDate(date, formatString) {
  if (typeof date === 'string') {
    date = parseISO(date)
  }
  if (date.getUTCFullYear() <= 1900) {
    // Date is unknown
    return ''
  }
  return format(date, formatString)
}
