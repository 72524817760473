import { createReducer } from 'reduxsauce'
import { Types } from 'src/redux/actions'

export const INITIAL_STATE = {
  // Generic information about user's level of auth
  isAuthenticated: false,
  isVerified: false,
  // Initial authorization task
  authFinished: false,
  authError: undefined,
  // auth state
  confirmingEmail: false,
  signingIn: false,
  signingUp: false,
  signInError: undefined,
  signUpError: undefined,
}

export const authSuccess = (
  state = INITIAL_STATE,
  { isAuthenticated, isVerified }
) =>
  Object.assign({}, state, { isAuthenticated, isVerified, authFinished: true })

export const authFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, { authError: error })

export const setConfirmingEmail = (
  state = INITIAL_STATE,
  { confirmingEmail }
) => Object.assign({}, state, { confirmingEmail })

export const signInRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, { signingIn: true })

export const signInFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, { signingIn: false, signInError: error })

export const signUpRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, { signingUp: true })

export const signUpSuccess = (state = INITIAL_STATE) =>
  Object.assign({}, state, { signingUp: false })

export const signUpFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, { signingUp: false, signUpError: error })

const HANDLERS = {
  [Types.AUTH_SUCCESS]: authSuccess,
  [Types.AUTH_FAILURE]: authFailure,
  [Types.SET_CONFIRMING_EMAIL]: setConfirmingEmail,
  [Types.SIGN_IN_WITH_EMAIL_REQUEST]: signInRequest,
  [Types.SIGN_IN_FAILURE]: signInFailure,
  [Types.SIGN_UP_WITH_EMAIL_REQUEST]: signUpRequest,
  [Types.SIGN_UP_SUCCESS]: signUpSuccess,
  [Types.SIGN_UP_FAILURE]: signUpFailure,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
