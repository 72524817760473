import styled, { css } from 'styled-components/macro'

export const ShownWrapper = styled.div`
  display: ${(props) => props.display ?? 'block'};

  ${(props) =>
    props.untilSm
      ? css`
          @media (min-width: 650px) {
            display: none;
          }
        `
      : ''}

  ${(props) =>
    props.untilMd
      ? css`
          @media (min-width: 960px) {
            display: none;
          }
        `
      : ''}

  ${(props) =>
    props.untilLg
      ? css`
          @media (min-width: 1280px) {
            display: none;
          }
        `
      : ''}

  ${(props) =>
    props.untilXl
      ? css`
          @media (min-width: 2000px) {
            display: none;
          }
        `
      : ''}
`
