import React from 'react'
import { useMatchMedia } from 'src/hooks/useMatchMedia'

export default (query, fallbackMatches = true) =>
  (ComposedComponent) => {
    function WindowSizeWrapper(props) {
      const matches = useMatchMedia(query, fallbackMatches)

      return <ComposedComponent {...props} matchesMediaQuery={matches} />
    }

    const composedComponentName =
      ComposedComponent.displayName || ComposedComponent.name || 'Component'

    WindowSizeWrapper.displayName = `windowSize(${composedComponentName})`
    return WindowSizeWrapper
  }
