import { take } from 'redux-saga/effects'
import querystring from 'src/services/querystring'
import { Types } from 'src/redux/actions'
import config from 'src/config'
import { locationChannel } from 'src/redux/sagas/channels'

function authActionsToEvents(gtag, action) {
  const { type, ...rest } = action
  if (type === Types.SIGN_IN_WITH_EMAIL_REQUEST) {
    gtag('event', 'login', { method: 'email' })
  } else if (type === Types.SIGN_UP_WITH_EMAIL_REQUEST) {
    gtag('event', 'sign_up', { method: 'email' })
  } else if (type === Types.RECOVER_PASSWORD_SUCCESS) {
    gtag('event', 'recover-password', { event_category: 'Auth' })
  } else if (type === Types.SET_CONFIRMING_EMAIL) {
    if (rest.confirmingEmail === true) {
      gtag('event', 'confirm-email', { event_category: 'Auth' })
    }
  }
}

function userActionsToEvents(gtag, action) {
  const { type, ...rest } = action
  if (type === Types.CREATE_PROFILE_SUCCESS) {
    const { user } = rest
    gtag('event', 'create-profile', {
      userType: user.userType,
      event_category: 'Profile Creation',
    })
  }
}

export function* sendOffer(gtag, history) {
  const parameters = querystring.parse(history.location.search.slice(1))

  if (parameters.offer) {
    gtag('event', 'landed-from-offer', {
      event_category: 'Landing',
      event_label: parameters.offer,
    })
    delete parameters.offer
    const newPath = {
      ...history.location,
      search: querystring.stringify(parameters),
    }
    history.replace(newPath)
  }
}

export function* sendPagePaths(gtag) {
  // Wait for the auth process to finish
  yield take(Types.AUTH_SUCCESS)
  let previousPath = null
  const locations = locationChannel()
  while (true) {
    const pagePath = yield take(locations)
    if (pagePath !== previousPath) {
      previousPath = pagePath
      gtag('config', config.googleAnalyticsId, { page_path: pagePath })
    }
  }
}

export function* analytics(gtag) {
  // Receive all actions in the store and perform
  // an action for each of them
  while (true) {
    const action = yield take()

    authActionsToEvents(gtag, action) // @redux/sagas/auth.js
    userActionsToEvents(gtag, action) // @redux/sagas/user.js
  }
}
