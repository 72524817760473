import { keyframes } from 'styled-components/macro'

export const borderLoadAnimationWidth = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
`

export const borderLoadAnimationHeight = keyframes`
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
`
