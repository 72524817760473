import auth from './auth'
import user from './user'
import team from './team'
import upload from './upload'
import payments from './payments'
import { createActions } from 'reduxsauce'

/*
  This is the Root Actions file, import all actions here
  and add them to the actions object below to generate
  all types and creators automatically
*/

const actions = {
  ...auth,
  ...user,
  ...team,
  ...upload,
  ...payments,
}

export const { Types, Creators } = createActions(actions)
