import { all, call, take, takeLatest } from 'redux-saga/effects'
import { auth, api, history } from 'src/components/App'
import { Types } from 'src/redux/actions'
import { gtag } from 'src/services/Analytics'
import hasFlag from 'src/services/Split'
import {
  initialAuthTask,
  signInWithEmail,
  signUpWithEmail,
  logout,
} from './auth'
import { analytics, sendPagePaths, sendOffer } from './analytics'
import { getUserProfile, createProfile } from './user'
import {
  getTeamNames,
  getTeamNamesFromLevel,
  getLevels,
  getBothLevels,
} from './team'
import { uploadScreen } from './upload'
import { paymentsGetProducts } from './payments'
import createChannels from './channels'
import { errorReporting } from './errorReporting'

function* reduxLogger() {
  const css = `
    color: green;
  `
  if (hasFlag('reduxlog')) {
    while (true) {
      const action = yield take(() => true)
      console.log('⚙️ %c redux action: (%s)', css, JSON.stringify(action))
    }
  }
}

function* rootSaga() {
  createChannels({
    history,
  })
  yield all([
    // These run at the start:
    call(sendOffer, gtag, history),
    call(initialAuthTask, auth, api, history),
    call(sendPagePaths, gtag),
    call(analytics, gtag),
    call(errorReporting),
    call(reduxLogger),
    // These wait for actions and execute sagas:
    // Auth
    takeLatest(Types.SIGN_IN_WITH_EMAIL_REQUEST, signInWithEmail, auth),
    takeLatest(Types.SIGN_UP_WITH_EMAIL_REQUEST, signUpWithEmail, auth),
    takeLatest(Types.LOGOUT, logout, auth),
    // Team
    takeLatest(Types.GET_TEAM_NAMES_REQUEST, getTeamNames, api),
    takeLatest(Types.GET_USER_PROFILE_REQUEST, getUserProfile, api),
    takeLatest(Types.CREATE_PROFILE_REQUEST, createProfile, api),
    takeLatest(Types.GET_LEVEL_REQUEST, getLevels, api),
    takeLatest(Types.GET_BOTH_LEVELS_REQUEST, getBothLevels, api),
    takeLatest(
      Types.GET_TEAM_NAMES_FROM_LEVEL_REQUEST,
      getTeamNamesFromLevel,
      api
    ),
    // Video Uploads
    takeLatest(Types.UPLOAD_START, uploadScreen, api, history),
    takeLatest(Types.UPLOAD_TEST_START_SAGA, function* ({ saga }) {
      yield call(saga)
    }),
    // Payments
    takeLatest(Types.PAYMENTS_GET_PRODUCTS_REQUEST, paymentsGetProducts, api),
  ])
}

export default rootSaga
