import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { Types } from 'src/redux/actions'

export const INITIAL_STATE = Immutable({
  attemptingToFetch: false,
  teams: null,
  levels: null,
  error: null,
  bothLevels: null,
  bothLevelsError: null,
  bothLevelsFetching: false,
  requestedTeam: null,
})

export const getTeamNamesRequest = (state = INITIAL_STATE) =>
  state.merge({ attemptingToFetch: true })

export const getTeamNamesSuccess = (state = INITIAL_STATE, { teams }) =>
  state.merge({ attemptingToFetch: false, teams })

export const getTeamNamesFailure = (state = INITIAL_STATE, { error }) =>
  state.merge({ attemptingToFetch: false, error })

export const getTeamNamesFromLevelRequest = (state = INITIAL_STATE) =>
  state.merge({ attemptingToFetch: true })

export const getTeamNamesFromLevelSuccess = (
  state = INITIAL_STATE,
  { teams }
) => state.merge({ attemptingToFetch: false, teams })

export const getTeamNamesFromLevelFailure = (
  state = INITIAL_STATE,
  { error }
) => state.merge({ attemptingToFetch: false, error })

export const getLevelRequest = (state = INITIAL_STATE) =>
  state.merge({ attemptingToFetch: true, levels: null })

export const getLevelSuccess = (state = INITIAL_STATE, { levels }) =>
  state.merge({ attemptingToFetch: false, levels })

export const getLevelFailure = (state = INITIAL_STATE, { error }) =>
  state.merge({ attemptingToFetch: false, error, levels: null })

export const getBothLevelsRequest = (state = INITIAL_STATE) =>
  state.merge({
    bothLevelsFetching: true,
    bothLevels: null,
    bothLevelsError: null,
  })

export const getBothLevelsSuccess = (state = INITIAL_STATE, { bothLevels }) =>
  state.merge({ bothLevelsFetching: false, bothLevels })

export const getBothLevelsFailure = (state = INITIAL_STATE, { error }) =>
  state.merge({
    bothLevelsFetching: false,
    bothLevelsError: error,
    bothLevels: null,
  })

const HANDLERS = {
  [Types.GET_TEAM_NAMES_REQUEST]: getTeamNamesRequest,
  [Types.GET_TEAM_NAMES_SUCCESS]: getTeamNamesSuccess,
  [Types.GET_TEAM_NAMES_FAILURE]: getTeamNamesFailure,
  [Types.GET_TEAM_NAMES_FROM_LEVEL_REQUEST]: getTeamNamesFromLevelRequest,
  [Types.GET_TEAM_NAMES_FROM_LEVEL_SUCCESS]: getTeamNamesFromLevelSuccess,
  [Types.GET_TEAM_NAMES_FROM_LEVEL_FAILURE]: getTeamNamesFromLevelFailure,
  [Types.GET_LEVEL_REQUEST]: getLevelRequest,
  [Types.GET_LEVEL_SUCCESS]: getLevelSuccess,
  [Types.GET_LEVEL_FAILURE]: getLevelFailure,
  [Types.GET_BOTH_LEVELS_REQUEST]: getBothLevelsRequest,
  [Types.GET_BOTH_LEVELS_SUCCESS]: getBothLevelsSuccess,
  [Types.GET_BOTH_LEVELS_FAILURE]: getBothLevelsFailure,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
