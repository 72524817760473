const localStorage = (() => {
  try {
    return window.localStorage
  } catch {
    return undefined
  }
})()

export const LocalStorage = {
  getItem(name) {
    return localStorage?.[name]
  },
  setItem(name, value) {
    if (localStorage) {
      localStorage[name] = value
    }
  },
  removeItem(name) {
    if (localStorage) {
      delete localStorage[name]
    }
  },
  supported: localStorage != null,
}
