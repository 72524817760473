import { WebAuth } from 'auth0-js'
import JwtDecode from 'jwt-decode'
import config from 'src/config'
import { LocalStorage } from 'src/services/LocalStorage'

/** This is the service responsible for communicating with Auth0 */

const getTokenExpiry = (idToken) => {
  try {
    const { exp: expires } = JwtDecode(idToken)
    return expires * 1000
  } catch (e) {
    console.error(e)
    return 0
  }
}

const { domain, clientID, redirectUri } = config

const auth0 = new WebAuth({
  clientID,
  domain,
  redirectUri,
  responseType: 'token id_token',
  scope: 'openid profile email user_metadata',
})

export default class Auth {
  getRedirectUri = () => {
    const currentParams = new URL(window.location).searchParams
    const ret = new URL(redirectUri)
    const preservedParams = ['next', 'claim']
    for (const param of preservedParams) {
      if (currentParams.get(param)) {
        ret.searchParams.append(param, currentParams.get(param))
      }
    }
    return ret.toString()
  }

  getStoredToken = () => LocalStorage.getItem('idToken')

  storeToken = (idToken) => {
    LocalStorage.setItem('idToken', idToken)
  }

  getTokenForLegacyTpe = () => {
    const idToken = this.getStoredToken()
    return { idToken, expiresIn: getTokenExpiry(idToken) }
  }

  signInWithEmail = ({ email, password }) => {
    // Note: auth0.login's callback always has an error.
    // A success results in a redirect to the redirectUri
    return new Promise((resolve) => {
      auth0.login(
        {
          realm: 'Username-Password-Authentication',
          username: email,
          password,
          redirectUri: this.getRedirectUri(),
        },
        (error) => {
          resolve({ error })
        }
      )
    })
  }

  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      auth0.parseHash((error, authResult) => {
        if (error) {
          reject(error)
        } else if (authResult?.idToken) {
          resolve(authResult.idToken)
        } else {
          reject(new Error('Invalid response from authentication provider'))
        }
      })
    })
  }

  expireIfNecessary = () => {
    // Check if the JWT is still valid for automatic Login
    const idToken = this.getStoredToken()
    if (idToken) {
      const expiry = getTokenExpiry(idToken)
      if (expiry < Date.now()) {
        LocalStorage.removeItem('idToken')
      }
    }
  }

  signUpWithEmail = ({ user }) => {
    const { email, password, firstName: firstname, lastName: lastname } = user

    return new Promise((resolve) => {
      auth0.signup(
        {
          connection: 'Username-Password-Authentication',
          email,
          password,
          user_metadata: { firstname, lastname },
        },
        (error) => {
          if (error) {
            resolve({ error })
          } else {
            resolve({})
          }
        }
      )
    })
  }

  recoverPassword = ({ email }) => {
    return new Promise((resolve) => {
      auth0.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email,
        },
        (error, response) => {
          if (error) {
            resolve({ error })
          } else {
            resolve({ response })
          }
        }
      )
    })
  }

  logout = ({ purge = true } = {}) => {
    const destinationPath = '/auth/login'
    const hadToken = Boolean(this.getStoredToken())

    LocalStorage.removeItem('idToken')

    if (hadToken && !purge) {
      auth0.logout({
        returnTo: `${window.location.origin}${destinationPath}`,
        clientID,
      })
    } else {
      window.location = destinationPath
    }
  }
}
