import { call, put } from 'redux-saga/effects'
import get from 'lodash/get'

import { Creators } from 'src/redux/actions'
import { getUserProfile } from 'src/redux/sagas/user'

// Get error message from auth0 or a default error message
const getAuth0Message = (error) => {
  return (
    error.errorDescription ||
    error.error_description ||
    error.description ||
    error.message ||
    'An authentication error has occurred'
  )
}

function* confirmEmail(auth, api, history) {
  yield put(Creators.setConfirmingEmail(true))
  try {
    const uuid = history.location.search.split('=').pop()
    if (uuid.startsWith('v2.')) {
      yield api.confirmEmail(uuid)
    } else {
      const jwt = yield api.confirmEmailLegacy(uuid)
      auth.storeToken(jwt)
    }
    window.location = '/profile/create-profile'
  } catch (err) {
    console.error('Error authenticating email link', err)
    alert('There was a problem authenticating your email link.')
    window.location = '/'
    return err
  }
}

function* claimPlayer(api, history) {
  const claimUuid = new URLSearchParams(history.location.search).get('claim')
  yield call(api.postSuccess, '/claim-player', { claim: claimUuid })

  window.location = '/'
}

/**
 * Signs in the user by:
 *  - checking if the user is coming from email confirmation
 *  - checking if the user is in the auth0 callback (id token will be in location.hash)
 *  - checking localStorage.idToken
 *
 * After this, the user's profile (user and coach/player if any) is loaded
 */
export function* initialAuthTask(auth, api, history) {
  const { pathname, search } = history.location

  function* getToken() {
    const storedToken = auth.getStoredToken()

    if (pathname === '/auth/callback') {
      try {
        const loginToken = yield call(auth.handleAuthentication)
        return loginToken
      } catch (authError) {
        if (authError.error === 'invalid_token' && history.action === 'POP') {
          // In this case the user clicked back or refreshed
          // on their browser just after logging in. Ignore the
          // resulting error
          return storedToken
        }
        throw authError
      }
    }
    return storedToken
  }

  try {
    auth.expireIfNecessary() // Expires session if the cookies we hold are too old
    // confirm email
    if (search.includes('uuid=')) {
      yield call(confirmEmail, auth, api, history)
      auth.logout()
      return // window.location replaced, do not login further
    }

    const idToken = yield call(getToken)

    let isAuthenticated = false
    let isVerified = false

    if (idToken) {
      auth.storeToken(idToken)
      api.config(idToken)

      if (pathname === '/auth/callback' && search.includes('claim=')) {
        yield call(claimPlayer, api, history)
        return
      }

      const { user, error } = yield call(getUserProfile, api)

      if (error) {
        if (
          /TokenExpiredException|JWTDecodeException/.test(
            get(error, 'res.data.type')
          )
        ) {
          // Token has expired or is faulty, let's remove the token and go to the login page
          auth.logout({ purge: true })
          return
        }
        yield put(Creators.authFailure(error.message))
        return
      } else {
        isAuthenticated = true
        isVerified = user.emailVerified
      }
    }

    yield put(Creators.authSuccess(isAuthenticated, isVerified))
  } catch (error) {
    console.error(error)
    yield put(Creators.authFailure(getAuth0Message(error)))
  }
}

export function* signInWithEmail(auth, { email, password }) {
  // auth0 only has an error situation for this case
  const { error } = yield call(auth.signInWithEmail, { email, password })
  yield put(Creators.signInFailure(getAuth0Message(error)))
}

export function* signUpWithEmail(auth, { user }) {
  const { email, password } = user
  const { error } = yield call(auth.signUpWithEmail, { user })
  if (error) {
    yield put(Creators.signUpFailure(getAuth0Message(error)))
  } else {
    yield put(Creators.signUpSuccess())
    yield put(Creators.signInWithEmailRequest(email, password))
  }
}

export function logout(auth) {
  auth.logout() // Redirects to /auth/login
}
