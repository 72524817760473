import { put, call } from 'redux-saga/effects'
import immutable from 'seamless-immutable'
import querystring from 'src/services/querystring'
import { Creators } from 'src/redux/actions'

const getErrorMessage = (serverError, defaultMessage) => {
  if (!serverError) return defaultMessage
  if (typeof serverError === 'string') return serverError
  return serverError.message || serverError.errorMessage || defaultMessage
}

export function* paymentsGetProducts(api, { couponCode = '', testMode } = {}) {
  try {
    const params = { couponCode }
    if (testMode) params.testMode = '1'
    const productsUrl = '/payment/credit-packages/prices?' + querystring.stringify(params)
    const { ok, errorMessage, data } = yield call(api.get, productsUrl)
    if (!ok) {
      yield put(
        Creators.paymentsGetProductsFailure({
          message: getErrorMessage(data, errorMessage),
          type: data ? data.type : undefined,
        })
      )
      return
    }

    yield put(Creators.paymentsGetProductsSuccess(immutable(data)))
  } catch (e) {
    console.error(e)
    yield put(
      Creators.paymentsGetProductsFailure({
        message: e.errorMessage || e.message,
      })
    )
  }
}
