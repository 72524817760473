import 'core-js/stable'
import React from 'react'
import { render } from 'react-dom'
import App, { history } from 'src/components/App'
import hasFlag from 'src/services/Split'
import 'src/services/GlobalVariableInterface'
import querystring from 'src/services/querystring'
import { Text, TextError } from 'src/assets/theme/text'
import { LocalStorage } from './services/LocalStorage'

const rootElement = document.getElementById('root')

const { flags } = querystring.parse(location.search.slice(1))

if (flags) {
  LocalStorage.setItem('flags', flags)
}

if (typeof module !== 'undefined' && module.hot) {
  module.hot.accept()
}

if (hasFlag('locationlog')) {
  const css = `
    color: blue;
    font-style: italic;
  `
  console.log('📍%c initial location:', css, history.location.pathname)
  history.listen((loc, action) => {
    console.log('📍%c location ' + action + ':', css, loc.pathname)
  })
}

// Crypto
if (!(window.crypto || window.webkitCrypto)) {
  alert(
    `This browser is not supported. Please use an up-to-date release of Chrome, Firefox, Safari or another modern browser for The Prospect Exchange.`
  )
}

if (module.hot) {
  module.hot.accept()
}

if (!LocalStorage.supported) {
  const needCookiesMessage = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}>
      <TextError fontSize={24}>
        It seems like cookies have been disabled.
      </TextError>
      <Text margin="10px">
        The Prospect Exchange app requires cookies to be enabled.
      </Text>
    </div>
  )
  render(needCookiesMessage, rootElement)
} else {
  render(<App />, rootElement)
}
