import styled, { css } from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { WHITE, PRIMARY } from 'src/assets/theme/colors'
import {
  borderLoadAnimationWidth,
  borderLoadAnimationHeight,
} from 'src/assets/theme/animations'

export const withLinkStyle = (Component) => styled(Component)`
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : 'none'};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  height: ${(props) => (props.height ? props.height : 'auto')};
  color: ${(props) => (props.color ? props.color : PRIMARY)};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : 'inherit'};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  font-family: Montserrat;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease;
  text-decoration: none;

  ${(props) =>
    props.borderAnimated
      ? css`
          border: none;

          &:hover {
            background-color: ${PRIMARY};
            color: ${WHITE};
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-color: ${PRIMARY};
            height: 1px;
            animation: ${borderLoadAnimationWidth} 0.3s linear forwards;
          }

          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            background-color: ${PRIMARY};
            width: 1px;
            animation: ${borderLoadAnimationHeight} 0.3s 0.3s linear forwards;
          }
        `
      : css`
          @media (min-width: 1024px) {
            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              right: 0;
              width: 0;
              height: 1px;
              background-color: ${PRIMARY};
              transition: 0.3s ease;
            }

            &:hover {
              &:before {
                width: 100%;
                left: 0;
              }
            }
          }
        `};
`

const StyledLink = withLinkStyle(NavLink)

export default StyledLink
