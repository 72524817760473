import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FrameSystemMessage from 'src/components/FrameSystemMessage'

function Authorization({ auth, userError, children }) {
  const { confirmingEmail, authError, authFinished } = auth

  if (confirmingEmail) {
    return <FrameSystemMessage raw title="Confirming your email address" />
  }

  if (userError) {
    return (
      <FrameSystemMessage raw error title="Could not fetch your profile.">
        {userError.message}
      </FrameSystemMessage>
    )
  }

  if (authError) {
    return (
      <FrameSystemMessage
        raw
        error
        title="An authentication error has occurred.">
        {authError}
      </FrameSystemMessage>
    )
  }

  if (!authFinished) {
    return <FrameSystemMessage raw title="Authenticating" />
  }

  return children
}

Authorization.propTypes = {
  auth: PropTypes.object,
  userError: PropTypes.object,
  children: PropTypes.node,
}

const mapStateToProps = ({ user, auth }) => ({
  auth,
  userError: user.error,
})

export default connect(mapStateToProps)(Authorization)
