import { api, auth, store, history } from 'src/components/App'
import { assert } from 'src/services/assert'

assert(window, 'you should not include this file from NodeJS')

window.tpe = {
  api,
  auth,
  store,
  history,
  get storeState() {
    return store.getState()
  },
}
