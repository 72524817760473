import styled, { css } from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import {
  DARK_BLUE,
  ACCENTS,
  WHITE,
  DARK_BLUE_LIGHT,
} from 'src/assets/theme/colors'
import { MainText } from 'src/assets/theme/text'

export const SideBarContainer = styled.div`
  display: none;
  @media (min-width: 1280px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 300px;
    padding: 50px 0 0 0;
    position: fixed;
    top: 0;
    overflow-y: auto;
    overscroll-behavior: contain;
    background-color: ${DARK_BLUE};
    box-shadow: 4px 0 16px 2px rgba(0, 0, 0, 0.3);
    z-index: 30;
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #3e4a52;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #566068;
    }
    /* Firefix-specific: */
    scrollbar-width: thin;
    scrollbar-color: #3e4a52 transparent;
  }
`

export const SidebarWidthKeeper = styled.div`
  width: 200px;
  margin-left: 46px;
`

export const LogoContainer = styled.div`
  width: 100%;
  margin: 0 0 70px 0;
  svg {
    width: 100%;
  }
`

export const SideBarLinksContainer = styled.div`
  .sidebarSearchTitle {
    margin-bottom: 16px !important;
  }
`

export const NavButtonDetail = styled.span`
  width: 22px;
  height: 22px;
  position: absolute;
  top: -11px;
  right: -11px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: ${WHITE};
  background-color: ${ACCENTS};
  border-radius: 50%;
`

const hoverArrowStyles = css`
  &::after,
  &::before {
    opacity: 0;
    content: '';
    position: absolute;
    right: 12px;
    width: 10px;
    height: 1px;
    background-color: ${WHITE};
    transition: transform 0.16s ease-in, opacity 0.12s ease-in-out;
  }
  &::after {
    top: 15px;
    background-color: ${WHITE};
    transform: rotate(45deg);
  }
  &::before {
    bottom: 15px;
    transform: rotate(-45deg);
  }

  &:hover {
    &::after {
      opacity: 1;
      transform: rotate(35deg) translate(5px, 0px);
    }
    &::before {
      opacity: 1;
      transform: rotate(-35deg) translate(5px, 0px);
    }
  }
`

export const SideBarLinkStyle = styled(NavLink)`
  position: relative;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 24px 0;
  padding: 0 0 0 12px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  /* for the LogOut link that has as='button' */
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  color: ${WHITE};
  background-color: #122634;
  cursor: pointer;
  text-decoration: none;

  &.active {
    background-color: ${ACCENTS};
    ${NavButtonDetail} {
      display: none;
    }
  }

  &:not(.active) {
    ${hoverArrowStyles}
  }

  & > svg {
    width: 22px;
    margin-right: 12px;
  }
`

export const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1px;
  margin: 40px 0;

  &:after {
    content: ' ';
    display: block;
    width: 100%;
    height: 1px;
    opacity: 20%;
    background-color: ${WHITE};
  }
`

export const PlayerInfoTable = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  margin: 0 0 24px 0;
  text-align: center;
`

export const PlayerTableHeader = styled(MainText)`
  display: inline-block;
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};
  padding: 8px;
  font-weight: 300;
  color: ${WHITE};
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  background-color: ${DARK_BLUE_LIGHT};
`

export const PlayerTableData = styled(PlayerTableHeader)`
  font-size: ${(props) => (props.isPlayerName ? '22px' : '16px')};
  font-weight: ${(props) => (props.isPlayerName ? '400' : '300')};
  line-height: ${(props) => (props.isPlayerName ? '26px' : '20px')};
  background-color: transparent;
  text-transform: capitalize;
  overflow: ${(props) => (props.isPlayerLevel ? 'hidden' : 'unset')};
  text-overflow: ${(props) => (props.isPlayerLevel ? 'ellipsis' : 'unset')};
`

export const PlayerTableInnerWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const PlayerTableDivider = styled.div`
  height: 100%;
  width: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.2);
`

export const CompanyLinksBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  flex-direction: column;
  margin: 120px 0 0 0;
  button {
    margin: 0;
  }
  a {
    text-decoration: none;
  }
  p {
    font-size: 12px;
    color: ${WHITE};
  }
`

export const CompanyLinksInner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  padding: 8px 0 0 0;
  margin: 8px 0 50px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`
