import PropTypes from 'prop-types'
import { DotsLoaderTextContainer, DotsBox } from './styles'

const DotsLoader = ({
  title,
  loaderTextSize,
  loaderTextWeight,
  dotsFontSize,
  dotsFontWeight,
}) => {
  return (
    <DotsLoaderTextContainer
      as="div"
      fontSize={loaderTextSize}
      fontWeight={loaderTextWeight}
    >
      {title}
      <DotsBox fontSize={dotsFontSize} fontWeight={dotsFontWeight}>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </DotsBox>
    </DotsLoaderTextContainer>
  )
}

DotsLoader.propTypes = {
  loaderTextSize: PropTypes.string,
  loaderTextWeight: PropTypes.number,
  title: PropTypes.string,
  dotsFontSize: PropTypes.string,
  dotsFontWeight: PropTypes.number,
}

export default DotsLoader
