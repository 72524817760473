import styled, { keyframes } from 'styled-components'
import { MainText } from 'src/assets/theme/text'

const dotsFaseInOut = keyframes`
  from{
    opacity:1;
  } to {
    opacity:0;
  }
`

export const DotsLoaderTextContainer = styled(MainText)`
  font-size: ${(props) => props.fontSize ?? '16px'};
  font-weight: ${(props) => props.fontWeight ?? '400'};
`

export const DotsBox = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: ${(props) => props.width ?? '32px'};
  margin: ${(props) => props.margin ?? '0 0 0 5px'};
  font-size: ${(props) => props.fontSize ?? 'inherit'};
  font-weight: ${(props) => props.fontWeight ?? 'inherit'};
  span {
    animation-name: ${dotsFaseInOut};
    animation-duration: 0.9s;
    animation-iteration-count: infinite;
    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
`
