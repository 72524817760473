import styled, { css } from 'styled-components/macro'
import { WHITE, LIGHT_GRAY_2 } from 'src/assets/theme/colors'

/**
 * access-container  -  is used only for SignUp/LogIn
 * terms-container   -  is used for the terms and contidions window
 * all other registration related blocks - (Confirm email/Change Password/Profile Creation) - are centered with the `centered` props  */

export const Page = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`

export const TransparentCurtain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1200;
  overflow: visible;
  @media screen and (max-width: 576px) and (orientation: portrait) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @media screen and (max-width: 576px) and (max-height: 730px) and (orientation: portrait) {
    justify-content: flex-start;
  }
  @media (min-width: 1024px) {
    height: 704px; /* height of the Hero + Header, to be able to click the Logos below*/
  }
`

export const Block = styled.div`
  transition: width 0.3s ease, height 0.3s ease;
  margin: ${(props) => props.margin || 0};
  padding: ${(props) => props.padding || 0};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'inherit'};

  &.md-confirm-email-block {
    & > div {
      @media (min-width: 600px) {
        padding: 15px 50px !important;
      }
    }
  }

  &.terms-container {
    width: 90%;
    max-width: 800px;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0;
      max-width: 600px;
      > div {
        padding: 50px !important;
      }
    }
    @media screen and (max-width: 576px) {
      position: absolute;
      top: 60px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform: none;
      > div {
        padding: 20px !important;
      }
    }
  }

  &.access-container {
    @media screen and (max-width: 576px) and (orientation: portrait) {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      margin: 0;
      transform: translateX(0);
      width: 90%;
    }
    @media screen and (max-width: 576px) and (max-height: 730px) and (orientation: portrait) {
      transform: translateY(60px);
      width: 100%;
      > div {
        box-shadow: none;
      }
    }
  }

  ${(props) =>
    props.centered
      ? css`
          position: absolute;
          left: 50%;
          top: 100px;
          transform: translateX(-50%);
          z-index: 1200;
        `
      : css``}

  ${(props) =>
    props.fluid
      ? css`
          width: auto;
        `
      : css`
          width: 290px;

          @media (min-width: 600px) {
            width: 420px;
          }
        `}

  @media (max-width: 640px) {
    margin: ${(props) =>
      props.smallMargin ? props.smallMargin : props.margin};
    padding: ${(props) =>
      props.smallPadding ? props.smallPadding : props.padding};
  }
`

export const DashboardView = styled.div`
  position: relative;
  height: 100%;
  background-color: ${WHITE};
  overflow: hidden;
  padding: ${(props) => (props.isPlayerView ? '0 !important' : '25px 15px')};
  font-family: Montserrat;
  @media (min-width: 700px) {
    padding: 30px 50px;
  }
  @media (min-width: 1400px) {
    padding: 50px 72px;
  }
`

export const Title = styled.h1`
  border-bottom: 1px solid ${LIGHT_GRAY_2};
  padding: 0 0 10px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-direction: row;
  }
  @media (min-width: 1024px) {
    margin: 0 0 15px;
    padding: 0 0 5px 0;
  }
`

export const PageHeader = styled.div`
  margin: 0 0 25px;

  @media (min-width: 1024px) {
    margin: 0 0 50px;
  }
`

export const SpacedItems = styled.div`
  & > * + * {
    margin-top: 15px;
  }
`
