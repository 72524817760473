import React, { Component } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Creators } from 'src/redux/actions'
import { NavLink, withRouter } from 'react-router-dom'
import Logo from 'src/components/Logo'
import { pipe } from 'src/utils/pipe'
import { formatDate } from 'src/utils/date'
import { MainGeneralButton } from 'src/assets/theme/buttons'
import { MainText } from 'src/assets/theme/text'
import { ReactComponent as YourDashboardSvg } from 'src/assets/img/buttonIcons/yourDashboard.svg'
import { ReactComponent as ViewedPlayersSvg } from 'src/assets/img/buttonIcons/viewedPlayers.svg'
import { ReactComponent as CoachContactSvg } from 'src/assets/img/buttonIcons/coachContact.svg'
import { ReactComponent as CreditsSvg } from 'src/assets/img/buttonIcons/credits.svg'
import { ReactComponent as EditSvg } from 'src/assets/img/buttonIcons/edit.svg'
import { ReactComponent as UploadSvg } from 'src/assets/img/buttonIcons/upload.svg'
import { ReactComponent as PowerOffSvg } from 'src/assets/img/buttonIcons/powerOff.svg'

import { canCommunicate } from 'src/services/Entitlements'
import { positionToShortString } from 'src/utils/strings'
import {
  SideBarContainer,
  SideBarLinksContainer,
  LogoContainer,
  NavButtonDetail,
  Divider,
  CompanyLinksBox,
  CompanyLinksInner,
  PlayerInfoTable,
  PlayerTableHeader,
  PlayerTableInnerWrapper,
  PlayerTableData,
  PlayerTableDivider,
  SideBarLinkStyle,
  SidebarWidthKeeper,
} from './styles'

function SideBarLink({ children, active, ...props }) {
  return (
    <SideBarLinkStyle
      className={cx(active && 'active')}
      activeClassName="active"
      {...props}
    >
      {children}
    </SideBarLinkStyle>
  )
}

SideBarLink.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
}

class SideBar extends Component {
  static propTypes = {
    user: PropTypes.object,
    profile: PropTypes.object,
    history: PropTypes.object,
    pageSpecificContent: PropTypes.node,
    canMessagePlayer: PropTypes.bool,
    toggleMessage: PropTypes.func,
    isPlayerPage: PropTypes.bool,
    playerPageProfile: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    logout: PropTypes.func,
  }

  isInitialized() {
    return this.props.user && this.props.profile
  }

  playerData = (data) => {
    const { canMessagePlayer, toggleMessage } = this.props
    const { userType } = this.props.user

    return (
      <>
        <Divider />
        <PlayerInfoTable>
          <PlayerTableData isPlayerName fullWidth>
            {`${data.givenName.toLowerCase()} ${data.familyName.toLowerCase()}`}
          </PlayerTableData>
          <PlayerTableHeader fullWidth>Team</PlayerTableHeader>
          <PlayerTableData fullWidth>{data.team?.name}</PlayerTableData>

          <PlayerTableInnerWrapper>
            <PlayerTableDivider />
            <PlayerTableHeader>DOB</PlayerTableHeader>
            <PlayerTableHeader>Gender</PlayerTableHeader>
            <PlayerTableData>
              {formatDate(data.dateOfBirth, 'MM/dd/yy')}
            </PlayerTableData>
            <PlayerTableData>{data.gender.split('')[0]}</PlayerTableData>
            <PlayerTableHeader>Position</PlayerTableHeader>
            <PlayerTableHeader>Level</PlayerTableHeader>
            <PlayerTableData>
              {positionToShortString(data.position)}
            </PlayerTableData>
            <PlayerTableData isPlayerLevel>
              {data.level
                ? data.level
                    .replace('Junior', 'Jr.')
                    .replace('High School', 'HS')
                : '-'}
            </PlayerTableData>
            {/* Hide SAT and GPA until we enable the feature */}
            <div hidden>
              <PlayerTableHeader>GPA</PlayerTableHeader>
              <PlayerTableHeader>SAT</PlayerTableHeader>
              <PlayerTableData>
                {data.gpaScore ? data.gpaScore : ' --- '}
              </PlayerTableData>
              <PlayerTableData>
                {data.satScore ? data.satScore : ' --- '}
              </PlayerTableData>
            </div>
          </PlayerTableInnerWrapper>
        </PlayerInfoTable>
        {userType === 'COACH' && (
          <MainGeneralButton
            onClick={() => (canMessagePlayer ? toggleMessage() : () => {})}
          >
            {canMessagePlayer ? 'Message Player' : 'Cannot Contact'}
          </MainGeneralButton>
        )}
      </>
    )
  }

  render() {
    const {
      user,
      profile,
      isAuthenticated,
      pageSpecificContent,
      isPlayerPage,
      playerPageProfile,
      logout,
    } = this.props

    if (!this.isInitialized()) {
      return <SideBarContainer />
    }

    return (
      <SideBarContainer>
        <SidebarWidthKeeper>
          <SideBarLinksContainer>
            <LogoContainer>
              <Logo isAuthenticated={isAuthenticated} />
            </LogoContainer>
            <SideBarLink
              to={
                user.userType === 'COACH'
                  ? '/dashboard-coach'
                  : `/players/${profile.id}`
              }
            >
              <YourDashboardSvg /> My Dashboard
            </SideBarLink>

            {user.userType === 'PLAYER' && (
              <>
                <SideBarLink exact to="/purchase-credits">
                  <CreditsSvg /> Buy Credits
                </SideBarLink>
                <SideBarLink exact to="/new-game">
                  <UploadSvg /> Game Upload
                  <NavButtonDetail className="navButtonDetail">
                    {profile.totalGamesRemaining}
                  </NavButtonDetail>
                </SideBarLink>
                {canCommunicate(user, profile) && (
                  <SideBarLink to="/send-to-coach">
                    <CoachContactSvg /> Coach Contact
                  </SideBarLink>
                )}
                <SideBarLink exact to="/profile-views-player">
                  <ViewedPlayersSvg /> Profile Views
                </SideBarLink>
                {this.playerData(profile)}
                <SideBarLink active to="/profile/edit-profile">
                  <EditSvg /> Edit Your Profile
                </SideBarLink>
              </>
            )}

            {user.userType === 'COACH' && (
              <>
                <SideBarLink exact to="/profile-views-coach">
                  <ViewedPlayersSvg /> Viewed Players
                </SideBarLink>
                <div hidden>
                  <SideBarLink active to="/profile/edit-profile">
                    <EditSvg /> Edit Your Profile
                  </SideBarLink>
                </div>
                {isPlayerPage &&
                  playerPageProfile != null &&
                  this.playerData(playerPageProfile)}
              </>
            )}
            {pageSpecificContent && (
              <>
                <Divider />
                {pageSpecificContent}
              </>
            )}
          </SideBarLinksContainer>
        </SidebarWidthKeeper>
        <SidebarWidthKeeper>
          <CompanyLinksBox>
            <SideBarLink as="button" onClick={() => logout()}>
              <PowerOffSvg />
              Log Out
            </SideBarLink>
            <CompanyLinksInner>
              <a href="https://theprospectexchange.com/faq/">
                <MainText>FAQ</MainText>
              </a>
              <NavLink to="/terms-and-conditions">
                <MainText>Terms</MainText>
              </NavLink>
              <NavLink to="/Privacy-Policy">
                <MainText>Privacy</MainText>
              </NavLink>
              <a href="mailto:info@theprospectexchange.com">
                <MainText>Contact</MainText>
              </a>
            </CompanyLinksInner>
          </CompanyLinksBox>
        </SidebarWidthKeeper>
      </SideBarContainer>
    )
  }
}

const mapDispatchToProps = {
  logout: Creators.logout,
}

const mapStateToProps = ({ user, auth }) => ({
  user: user.user,
  profile: user.profile,
  isAuthenticated: auth.isAuthenticated,
})

export default pipe(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SideBar)
