import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import { customTheme } from 'src/assets/theme/material-ui-theme'
import Routes from 'src/components/Routes'
import Authorization from 'src/components/Authorization'
import API from 'src/services/API'
import Auth from 'src/services/Auth'
import hasFlag from 'src/services/Split'

import { configureStore } from 'src/redux/config'

import './global-styles.css'
import { errorHandler } from 'src/services/ErrorReporter'

/*
  Creates an instance of each service:
    - Api is used to fetch and send data to pm-rest
    - Auth is used to authenticate with Auth0
*/

export const api = new API()
export const auth = new Auth()
export const history = createBrowserHistory()
export const store = configureStore()

function renderError(error) {
  const stack = hasFlag('staging') && (
    <div
      style={{ fontFamily: 'monospace', fontSize: 14, whiteSpace: 'pre-line' }}>
      Stack:{'\n'}
      <code>
        {error.stack || error.message || JSON.stringify(error, null, 2)}
      </code>
    </div>
  )
  return (
    <div style={{ margin: '1em' }}>
      <p>
        An internal error has occurred. Please{' '}
        <a
          href=""
          onClick={(event) => {
            event.preventDefault()
            location.reload()
          }}>
          Refresh
        </a>{' '}
        and try again.
      </p>
      <p>
        <small>
          <code>{error.message}</code>
        </small>
      </p>
      {stack}
    </div>
  )
}

class App extends React.Component {
  state = { error: null }
  static getDerivedStateFromError(error) {
    errorHandler.report(error)
    console.error(error)
    return { error }
  }

  render() {
    const { error } = this.state

    return (
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        {error ? (
          renderError(error)
        ) : (
          <Provider store={store}>
            <Router history={history}>
              <Authorization>
                <Routes />
              </Authorization>
            </Router>
          </Provider>
        )}
      </ThemeProvider>
    )
  }
}

export default App
