import auth from './auth'
import user from './user'
import team from './team'
import upload from './upload'
import payments from './payments'
import { combineReducers } from 'redux'

/*
  This is the Root Reducer file, import all reducers here
  and add them to the reducers object below to combine
  them all.

  We use Immutable in each reducer to be sure we are not mutating
  data in our application.

  We use the HANDLERS object to point out which function from the
  reducer should be used when a type is called.
*/

export const reducers = {
  auth,
  user,
  team,
  upload,
  payments,
}

export default combineReducers(reducers)
