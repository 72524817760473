import React from 'react'
import styled from 'styled-components/macro'
import {
  PRIMARY,
  LIGHT_RED,
  DARK_BLUE,
  LIGHT_RED_2,
} from 'src/assets/theme/colors'

export const Text = styled.span`
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  color: ${(props) => (props.color ? props.color : DARK_BLUE)};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : 'Montserrat'};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : 'inherit'};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'normal')};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  display: ${(props) => (props.inline ? 'inline' : 'block')};
  cursor: ${(props) => props.cursor || 'normal'};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : ''};
  ${(props) =>
    props.isSmallCentered
      ? `
    @media (max-width: 960px) {
      text-align: center;
    }
  `
      : ``}
  @media (max-width: 640px) {
    font-size: ${(props) =>
      props.smallFontSize ? props.smallFontSize : props.fontSize}px;
    margin: ${(props) =>
      props.smallMargin ? props.smallMargin : props.margin};
  }
`

export const TextNormal = (props) => {
  return <Text fontSize={16} {...props} />
}

export const LinkText = (props) => {
  return (
    <Text
      fontSize={16}
      fontWeight={500}
      color={PRIMARY}
      margin="5px 0"
      cursor="pointer"
      {...props}
    />
  )
}

export const TextLink = LinkText

export const TitleText = (props) => {
  return (
    <Text
      fontSize={24}
      fontWeight={500}
      letterSpacing="0.2px"
      color={LIGHT_RED_2}
      textTransform="uppercase"
      {...props}
    />
  )
}

export const TextTitle = TitleText

export const TextError = (props) => {
  return (
    <Text
      fontSize={16}
      fontWeight={500}
      letterSpacing="0.2px"
      color={LIGHT_RED}
      {...props}
    />
  )
}

// ===================================
export const MainTitle = styled.h3`
  font-size: 22px;
  font-family: Montserrat, sans-serif;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 900)};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  color: ${(props) => (props.color ? props.color : DARK_BLUE)};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : ''};
`

export const MainText = styled.p`
  font-size: 16px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-family: Montserrat, sans-serif;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  color: ${(props) => (props.color ? props.color : DARK_BLUE)};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : ''};
`

export const MediumText = styled(MainText)`
  font-size: 18px;
`
