import { channel } from 'redux-saga'
import {
  race,
  take,
  call,
  put,
  select,
  takeEvery,
  all,
  cancelled,
} from 'redux-saga/effects'
import { Types, Creators } from 'src/redux/actions'
import { errorHandler } from 'src/services/ErrorReporter'
import hasFlag from 'src/services/Split'

// Randomly inject errors when the chaos flag is enabled
// Same principle as https://principlesofchaos.org/
function chaos() {
  if (hasFlag('chaos') && Math.random() < 0.125) {
    throw new Error('<This is an injected error>')
  }
}

export let uploadContext = null
export function* setContext(overrides) {
  if (overrides == null) uploadContext = null

  const playerId = yield select((store) => store.user.profile.id)
  uploadContext = {
    playerId,
    completed: false,
    ...overrides,
  }
}

// Called when the upload page mounts
export function* uploadScreen(api, history) {
  yield put(Creators.uploadReady()) // Sets loading: false (do we need it?)

  try {
    chaos()
    yield call(setContext, { api, history })
    yield race({
      upload: call(uploadProcess),
      unmounted: take(Types.UPLOAD_RESET),
    })
  } catch (e) {
    console.error(e)
    errorHandler.report(e)
    // TODO this is a rare fatal crash. Make this a page
    alert('A fatal error has occurred: ' + e.message)
  } finally {
    uploadContext = null
  }
}

export function* uploadProcess() {
  try {
    yield take(Types.UPLOAD_BUTTON_PRESS)

    yield put(Creators.uploadSetUploading(true))

    while (true) {
      try {
        yield call(setContext, {
          ...uploadContext,
          gameId: yield call(createGameId),
        })

        yield call(initialCreateAndDeleteVideosOnServer)

        console.info('uploadLoop starting')
        yield race({
          syncWithServerVideos: call(createAndDeleteVideosOnServer),
          upload: call(uploadLoop),
        })
        console.info('uploadLoop done')

        yield call(sendToIceberg)
        chaos()
        break
      } catch (err) {
        yield call(showUploadErrorAndWaitForInput, err.message)
      }
    }

    yield put(Creators.uploadSetUploading(false))

    yield call(uploadCompleteDialog)

    uploadContext.history.push('/dashboard-player')
  } finally {
    if (yield cancelled()) {
      yield call(clearUnfinishedGame)
    }
  }
}

const selectNextVideoToUpload = (state, erroredVideos) => {
  const { videos, serverVideos } = state.upload

  for (const serverVideo of serverVideos) {
    const clientVideo = videos.find((cv) => cv.id === serverVideo.clientVideoID)

    if (
      clientVideo &&
      clientVideo.progress !== 100 &&
      !erroredVideos.has(clientVideo.id)
    ) {
      return { serverVideo, clientVideo }
    }
  }
}

export function* uploadLoop() {
  const erroredVideos = new Set()

  while (true) {
    const toUpload = yield select((state) =>
      selectNextVideoToUpload(state, erroredVideos)
    )

    if (!toUpload) break

    try {
      yield call(uploadVideoFile, toUpload)
      chaos()
    } catch (e) {
      // Mark video as errored
      console.error(e)
      erroredVideos.add(toUpload.clientVideo.id)
      yield put(
        Creators.uploadSetVideoError(toUpload.clientVideo.id, e.message)
      )
      yield call(waitForNetwork)
    }
  }

  console.info(
    'uploadLoop: inner upload loop finished' +
      (erroredVideos.size ? ' with errors' : '')
  )

  if (erroredVideos.size) {
    const errorMessage =
      erroredVideos.size > 1
        ? 'Some of your files failed to upload'
        : 'One of your files failed to upload'

    // One of the videos failed to upload (despite retries).
    throw new Error(errorMessage)
  }

  const successfulUploads = yield select((state) => {
    let uploads = 0
    for (const video of state.upload.videos) {
      if (video.progress === 100) {
        uploads++
      }
    }
    return uploads
  })

  const gamesheet = yield select(({ upload }) =>
    upload.videos.find((v) => v.isGameSheet)
  )
  if (!gamesheet) {
    throw new Error('Please add a gamesheet')
  }

  if (!successfulUploads) {
    // Zero files uploaded successfully.
    // Let's have the user click upload when they are ready.

    throw new Error('All uploads have been cancelled')
  }

  // Done!
}

function* showUploadErrorAndWaitForInput(
  errorMessage = 'An unknown error occurred'
) {
  console.info('Showing upload error, waiting for input')

  // Show the user the error, wait for input
  yield put(Creators.uploadError(errorMessage))
  yield put(Creators.uploadSetUploading(false))
  const input = yield take(Types.UPLOAD_BUTTON_PRESS)
  yield put(Creators.uploadError(''))
  yield put(Creators.uploadSetUploading(true))

  console.info('got input')
  return input
}

function* uploadCompleteDialog() {
  uploadContext.completed = true

  yield put(Creators.uploadSetCompleteDialog(true))

  yield take(Types.UPLOAD_DIALOG_RESULT)
}

export function* clearUnfinishedGame() {
  const uploadIds = yield select(({ upload }) =>
    upload.serverVideos.map((v) => v.id)
  )
  const { api, gameId, completed } = uploadContext

  if (gameId && !completed) {
    yield api.post(`/upload/clear-game?uploadIds=${uploadIds.join(',')}`)
  }
}

// -- Low level functions below --

const wait = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, uploadContext.testing ? 0 : ms)
  })

function* waitForNetwork() {
  while (navigator.onLine === false) {
    yield wait(4000)
  }

  yield wait(1000)
}

function callWithRetries(fn, ...args) {
  return call(function* () {
    let retries = 10
    while (true) {
      try {
        return yield call(fn, ...args)
      } catch (e) {
        retries--

        if (retries <= 0) {
          throw e
        }

        yield call(waitForNetwork)
      }
    }
  })
}

export function* uploadVideoFile({ clientVideo, serverVideo }) {
  const { api } = uploadContext

  let cancelFn = () => {}
  const progressChan = channel()

  console.info('getting ready to upload', { clientVideo, serverVideo })

  try {
    chaos()
    yield race({
      done: call(api.uploadGameFile, {
        endpoint: serverVideo.uploadUrl,
        file: clientVideo.fileObject,
        onCancelFn: (cancel) => {
          cancelFn = cancel
        },
        setProgress: (progress) => {
          progressChan.put(progress)
        },
      }),
      cancelled: take(
        ({ type, id }) =>
          type === Types.UPLOAD_SERVER_VIDEO_REMOVE && id === serverVideo.id
      ),
      progress: call(function* () {
        while (true) {
          const progress = yield take(progressChan)
          yield put(Creators.uploadSetVideoProgress(clientVideo.id, progress))
        }
      }),
    })
  } finally {
    cancelFn()
  }
}

// Gets the game information that we just gathered by uploading (video IDs, scoresheet URL, ...)
function* getExtraGameInfo() {
  const { serverVideos, getLocationString } = yield select(
    ({ upload }) => upload
  )
  const apiUrl = `${window.location.origin}/api`

  const scoresheet = serverVideos.find((v) => v.isGameSheet)
  const videos = serverVideos.filter((v) => !v.isGameSheet)

  return {
    scoresheet: `${apiUrl}/upload/view-video/${scoresheet.id}`,
    videoLocation: getLocationString(
      videos.map((v) => `${apiUrl}/upload/view-video/${v.id}`)
    ),
    markAsDone:
      `${apiUrl}/upload/clear-game/?` +
      new URLSearchParams({
        uploadIds: serverVideos.map((v) => v.id),
      }),
  }
}

function* sendToIceberg() {
  const { api } = uploadContext
  const { game, serverVideos } = yield select(({ upload }) => upload)

  yield callWithRetries(api.postSuccess, '/upload/send-to-iceberg', {
    videoPayload: { ...game, ...(yield call(getExtraGameInfo)) },
    uploadIds: serverVideos.map((v) => v.id),
  })
}

function* createGameId() {
  const { api } = uploadContext
  const { id } = yield callWithRetries(api.postSuccess, '/upload/new-game')
  return id
}

function* createVideoOnServer({ video }) {
  const { api, gameId } = uploadContext
  const { name, size } = video.fileObject
  const extension = name?.split('.').pop()

  const { uploadUrl, uploadId } = yield callWithRetries(
    api.postSuccess,
    `/upload/create-storage-url`,
    { gameId, extension: extension ? `.${extension}` : null, size }
  )

  yield put(
    Creators.uploadServerVideoAdd(video.id, {
      uploadUrl,
      id: uploadId,
      isGameSheet: video.isGameSheet,
    })
  )
}

function* deleteVideoOnServer({ id }) {
  const { api } = uploadContext

  yield callWithRetries(function* () {
    const { ok, status, errorMessage } = yield api.post(
      `/upload/clear-game?uploadIds=${id}`
    )

    if (!(ok || status === 404)) {
      throw new Error(errorMessage)
    }
  })

  yield put(Creators.uploadServerVideoRemove(id))
}

function* initialCreateAndDeleteVideosOnServer() {
  const toUpload = yield select(({ upload }) => upload.videos)

  // TODO: Not necessary to do this at the start!
  for (const video of toUpload) {
    // create every video on the server, add its ID to the videos array
    yield call(createVideoOnServer, { video })
  }
}

export function* createAndDeleteVideosOnServer() {
  yield all([
    takeEvery(Types.UPLOAD_VIDEO_ADD, createVideoOnServer),
    takeEvery(Types.UPLOAD_VIDEO_REMOVE, function* ({ id }) {
      const serverVideos = yield select((state) => state.upload.serverVideos)
      const toDelete = serverVideos.find((v) => v.clientVideoID === id)

      if (toDelete) {
        yield call(deleteVideoOnServer, { id: toDelete.id })
      }
    }),
  ])
}
