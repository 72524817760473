export const ruleRunner = (field, name, ...validations) => {
  return (state) => {
    for (const validation of validations) {
      const errorMessageFunc = validation(state[field], state)
      if (errorMessageFunc) {
        return { [field]: errorMessageFunc(name) }
      }
    }
    return null
  }
}

export const validate = (state, runners) => {
  return runners.reduce((memo, runner) => {
    return Object.assign(memo, runner(state))
  }, {})
}
