import hasFlag from 'src/services/Split'

if (process.env.NODE_ENV !== 'test') {
  if (!window.dataLayer)
    console.error('running in an environment without window.dataLayer')
  if (typeof window.gtag !== 'function')
    console.error('window.gtag is not a function')
}

// Calls gtag with the arguments that were given.
// Documentation: https://developers.google.com/gtagjs/reference/api

const inProd = /theprospectexchange.com$/.test(location.hostname)

export const gtag = (...args) => {
  if (process.env.NODE_ENV === 'test') return

  if (hasFlag('analyticslog')) {
    const css = `
      color: orange;
      font-style: italic;
    `
    console.log(
      '👀%c analytics call: gtag(%s)',
      css,
      args.map((arg) => JSON.stringify(arg)).join(', ')
    )
  }

  if (!window.gtag || hasFlag('automatedtest') || !inProd) {
    return
  }

  return window.gtag(...args)
}
