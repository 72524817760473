import { call, put, all, cancelled } from 'redux-saga/effects'
import { Creators } from '../actions'

export function* getTeamNames(api) {
  try {
    const { data, ok } = yield call(api.getTeamNames)
    const message =
      data != null ? data.message : 'Error trying to fetch Team Names'

    if (ok) {
      yield put(Creators.getTeamNamesSuccess(data))
    } else {
      yield put(Creators.getTeamNamesFailure(message))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* getTeamNamesFromLevel(api, { gender, level }) {
  try {
    const { data, ok } = yield call(api.getTeamNamesFromLevel, gender, level)
    const message =
      data != null ? data.message : 'Error trying to fetch Team Names'

    if (ok) {
      yield put(Creators.getTeamNamesFromLevelSuccess(data))
    } else {
      yield put(Creators.getTeamNamesFromLevelFailure(message))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* getLevels(api, { gender, isCoach }) {
  try {
    const { data, ok } = isCoach
      ? yield call(api.get, `/level/${gender}?role=COACH`)
      : yield call(api.get, `/level/${gender}`)
    const message = data != null ? data.message : 'Error trying to fetch levels'

    if (ok) {
      const sortedData = data.sort()
      yield put(Creators.getLevelSuccess(sortedData))
    } else {
      yield put(Creators.getLevelFailure(message))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* getBothLevels(api) {
  try {
    const results = yield all([
      call(api.get, '/level/MALE'),
      call(api.get, '/level/FEMALE'),
    ])
    const allOk = results.every((result) => result.ok)
    if (allOk) {
      const bothLevels = { MALE: results[0].data, FEMALE: results[1].data }
      yield put(Creators.getBothLevelsSuccess(bothLevels))
    } else {
      yield put(Creators.getBothLevelsFailure('Could not get levels'))
    }
  } catch (error) {
    console.error(error)
    alert(
      'Something went terribly wrong. If this error persists, please contact us at info@theprospectexchange.com'
    )
  } finally {
    if (yield cancelled()) {
      yield put(Creators.getBothLevelsFailure('Could not get levels'))
    }
  }
}
