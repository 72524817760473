import { call, put } from 'redux-saga/effects'
import { Creators } from '../actions'

async function fetchUser(api) {
  return await api.postSuccess('/auth/get-user')
}

export function* getUserProfile(api) {
  try {
    const { user, profile } = yield fetchUser(api)
    yield put(Creators.getUserProfileSuccess(user, profile))
    return { user, profile }
  } catch (e) {
    console.error(e)
    yield put(Creators.getUserProfileFailure(e))
    return { error: e }
  }
}

export function* createProfile(api, { profile }) {
  try {
    const { data, ok } = yield call(api.createUserProfile, { profile })

    if (ok) {
      const { user } = yield call(fetchUser, api)
      // Don't put() the profile success, just redirect!
      if (user.userType === 'COACH') {
        window.location = '/dashboard-coach'
      } else {
        window.location = '/dashboard-player'
      }
    } else {
      const msg =
        data.message != null
          ? `${data.statusCode}: ${data.message}`
          : 'Please check your filled information or try again later.'
      alert(msg)
      yield put(Creators.createProfileFailure(msg))
    }
  } catch (error) {
    console.error(error)
    yield put(
      Creators.createProfileFailure(
        'An unexpected error has occurred while attempting to create your profile.'
      )
    )
  }
}
