import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Image } from './styles'

/*
  The Prospect Exchange's logo
*/

const imageProps = {
  alt: 'The Prospect Exchange',
}

const linkStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}

const Logo = (props) => {
  return props.isAuthenticated ? (
    <Link to="/" style={linkStyle}>
      <Image {...imageProps} />
    </Link>
  ) : props.noRoutesToOtherPages ? (
    <Image {...imageProps} />
  ) : (
    <a href="http://www.theprospectexchange.com" style={linkStyle}>
      <Image {...imageProps} />
    </a>
  )
}

Logo.propTypes = {
  isAuthenticated: PropTypes.bool,
  noRoutesToOtherPages: PropTypes.bool,
}

export default Logo
