import Axios from 'axios'
import throttle from 'lodash/throttle'

import config from 'src/config'
import { create } from 'apisauce'

/*
  This is the service that access, fetch and send all
  data to our API PM-REST
*/

const { API: API_URL } = config

Axios.defaults.baseURL = API_URL

class API {
  constructor(baseURL = API_URL) {
    this.api = create({
      baseURL,
      headers: { 'Content-Type': 'application/json' },
      timeout: 20000,
    })
  }

  /** Adds the token as the Authorization header */
  config = (token) => {
    Axios.defaults.headers.common.Authorization = `Bearer ${token}`
    this.api.headers.Authorization = `Bearer ${token}`
  }

  /** Use this for a simple GET request */
  get = async (endpoint, params, axiosOptions) => {
    const res = await this.api.get(endpoint, params, axiosOptions)
    res.errorMessage = res.ok ? '' : this.getErrorMessage(res)
    return res
  }

  put = async (endpoint, data, axiosOptions) => {
    const res = await this.api.put(endpoint, data, axiosOptions)
    res.errorMessage = res.ok ? '' : this.getErrorMessage(res)
    return res
  }

  post = async (endpoint, data, axiosOptions) => {
    const res = await this.api.post(endpoint, data, axiosOptions)
    res.errorMessage = res.ok ? '' : this.getErrorMessage(res)
    return res
  }

  delete = async (endpoint, params, axiosOptions) => {
    const res = await this.api.delete(endpoint, params, axiosOptions)
    res.errorMessage = res.ok ? '' : this.getErrorMessage(res)
    return res
  }

  getErrorMessage = ({ status, data, problem }) => {
    let humanMessage = 'Server error'
    if (status === 417 && data?.message) {
      // Payment error
      return data.message
    }
    if (data && data.error) {
      humanMessage = data.message || data.error
    }
    if (problem === 'NETWORK_ERROR') {
      return 'Network not available'
    }
    if (problem === 'TIMEOUT_ERROR') {
      return 'Request has timed out'
    }
    if (problem === 'CONNECTION_ERROR') {
      return 'Could not reach the server'
    }
    return 'Error code ' + status + '. ' + humanMessage
  }

  getError = (res) => {
    const error = new Error(this.getErrorMessage(res))

    error.res = res

    return error
  }

  getSuccess = async (...args) => {
    const res = await this.get(...args)
    if (res.status !== 200) {
      throw this.getError(res)
    }
    return res.data
  }

  postSuccess = async (...args) => {
    const res = await this.post(...args)
    if (res.status !== 200) {
      throw this.getError(res)
    }
    return res.data
  }

  deleteSuccess = async (...args) => {
    const res = await this.delete(...args)
    if (res.status !== 200) {
      throw this.getError(res)
    }
    return res.data
  }

  putSuccess = async (...args) => {
    const res = await this.put(...args)
    if (res.status !== 200) {
      throw this.getError(res)
    }
    return res.data
  }

  confirmEmail = (signedVerification) => {
    return this.postSuccess(
      `/auth/confirm-email?verificationString=${signedVerification}`,
      {}
    )
  }

  confirmEmailLegacy = async (uuid) => {
    delete Axios.defaults.headers.common.Authorization
    const {
      data: { jwt },
    } = await Axios.get(`/token/jwt?uuid=${uuid}`)
    return jwt
  }

  deleteUser = () => {
    return this.api.post('/auth/delete-user', {})
  }

  _sanitizeTeamNames = (res) => {
    if (Array.isArray(res.data)) {
      res.data = res.data.filter(
        (team) =>
          team.id > 0 && typeof team.name === 'string' && team.name.length
      )
    }
    return res
  }

  getTeamNames = async () => {
    return this._sanitizeTeamNames(await this.api.get('/team'))
  }

  getTeamNamesFromLevel = async (gender, level) => {
    return this._sanitizeTeamNames(
      await this.api.get(`level/${gender}/${level}/team`)
    )
  }

  createUserProfile = ({
    profile: { playerOrCoach, firstName, lastName, ...profile },
  }) => {
    const zeroPad = (n) => (n < 10 ? `0${n}` : String(n))
    const zeroPaddedToNumber = (s) => (s[0] === '0' ? s.slice(1) : s)

    const nameObj = {
      givenName: firstName,
      familyName: lastName,
    }

    if (playerOrCoach === 'player') {
      const {
        dateOfBirth: [year, month, day],
      } = profile
      const dateOfBirth = `${year}-${zeroPad(
        zeroPaddedToNumber(month)
      )}-${zeroPad(zeroPaddedToNumber(day))}`
      profile = { ...profile, ...nameObj, dateOfBirth }
    }
    const userType = playerOrCoach === 'player' ? 'PLAYER' : 'COACH'
    return this.api.post('/user-profile', {
      userType,
      profile,
    })
  }

  uploadGameFile = async ({
    endpoint,
    file,
    onCancelFn = () => {
      console.warn('API: onCancelFn not set')
    },
    setProgress = () => null,
  }) => {
    let cancelled = false

    const setProgressThrottled = throttle(setProgress, 1000)

    const cancelFn = () => {
      console.log('API: cancelFn called')
      cancelled = true
      setProgressThrottled.cancel()
      try {
        xhr.abort()
      } catch (e) {}
    }

    const xhr = new XMLHttpRequest()

    onCancelFn(cancelFn)

    try {
      await new Promise((resolve, reject) => {
        console.log('opening XHR')
        xhr.onload = resolve
        xhr.onerror = reject
        xhr.open('PUT', endpoint)
        xhr.setRequestHeader('content-type', file.type)

        xhr.upload.onprogress = ({ total, loaded, lengthComputable }) => {
          if (cancelled || !lengthComputable) {
            return
          }

          const progress = Math.round((loaded * 100) / total)

          // Clamp progress at 10% minimum
          setProgressThrottled(progress < 10 ? 10 : progress)
        }

        xhr.send(file)
      })

      console.log('XHR onload fired')

      if (cancelled) return

      if (xhr.status !== 200) {
        throw new Error('Non-200 response from GCP')
      }

      setProgress(100)
    } finally {
      cancelFn() // Stops everything related to the request
    }
  }
}

// Call fn(...args) up to 10 times. Returns when it stops throwing errors.
// If the browser window is offline, it'll wait until it's online without spending retries
export const withRetries = async (fn, ...args) => {
  let toThrow

  for (let i = 0; i < 10; i++) {
    try {
      return await fn(args)
    } catch (e) {
      toThrow = e

      while (!navigator.onLine) {
        await new Promise((resolve) => setTimeout(resolve, 3000))
      }
    }
  }

  throw toThrow
}

export default API
