import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import Shown from 'src/components/Shown'
import Header from 'src/components/Header'
import SideBar from 'src/components/SideBar'
import { Page } from 'src/assets/theme/containers'
import { ReactComponent as ArrowSvg } from 'src/assets/img/buttonIcons/videoNext.svg'
import { GridSpacerWrapper, Spacer, TopScroll } from './styles'

const FrameWithSideBar = ({
  children,
  gridProps,
  noRoutesToOtherPages,
  canMessagePlayer,
  toggleMessage,
  isPlayerPage,
  playerPageProfile,
  pageSpecificContent,
}) => {
  const [showScrollTop, setShowScrollTop] = useState(0)

  const checkScrollTop = () => {
    const scrolledDistance = window.scrollY
    setShowScrollTop(scrolledDistance >= 700)
  }

  const scrollTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop)
    return () => {
      window.removeEventListener('scroll', checkScrollTop)
    }
  }, [])

  return (
    <Page>
      <Shown untilLg>
        <Header noRoutesToOtherPages={noRoutesToOtherPages} />
      </Shown>
      <SideBar
        canMessagePlayer={canMessagePlayer}
        toggleMessage={toggleMessage}
        isPlayerPage={isPlayerPage}
        playerPageProfile={playerPageProfile}
        pageSpecificContent={pageSpecificContent}
      />
      <GridSpacerWrapper className="GridSpacerWrapper">
        {' '}
        {/* remove the Class. just there to find it in the DOM */}
        <Spacer />
        <Grid container style={{ flex: '1' }}>
          <Grid item xs={12} {...gridProps}>
            {children}
          </Grid>
        </Grid>
        <TopScroll onClick={scrollTop} showScrollTop={showScrollTop}>
          <ArrowSvg />
        </TopScroll>
      </GridSpacerWrapper>
    </Page>
  )
}

FrameWithSideBar.propTypes = {
  children: PropTypes.node,
  gridProps: PropTypes.object,
  noRoutesToOtherPages: PropTypes.bool,
  isPlayerPage: PropTypes.bool,
  canMessagePlayer: PropTypes.bool,
  toggleMessage: PropTypes.func,
  playerPageProfile: PropTypes.object,
  pageSpecificContent: PropTypes.node,
}

export default FrameWithSideBar
