import immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { Types } from 'src/redux/actions'

const INITIAL_STATE = immutable({
  products: null,
  productsFetching: false,
  productsError: null,
  couponCode: '',
})

const getProductsRequest = (state = INITIAL_STATE, { couponCode = '' }) =>
  state.merge({
    products: null,
    productsFetching: true,
    productsError: null,
    couponCode,
  })

const getProductsSuccess = (state = INITIAL_STATE, { products }) =>
  state.merge({
    products,
    productsFetching: false,
    productsError: null,
  })

const getProductsFailure = (state = INITIAL_STATE, { error }) =>
  state.merge({
    products: null,
    productsFetching: false,
    productsError: error,
  })

const HANDLERS = {
  [Types.PAYMENTS_GET_PRODUCTS_REQUEST]: getProductsRequest,
  [Types.PAYMENTS_GET_PRODUCTS_SUCCESS]: getProductsSuccess,
  [Types.PAYMENTS_GET_PRODUCTS_FAILURE]: getProductsFailure,
}

const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
