import React from 'react'
import PropTypes from 'prop-types'
import check from 'src/services/Split'

/*
 * Hide things behind a feature flag. Default flag is "staging"
 *
 * More information about flags here:
 *
 *   https://github.com/BenchMetricsLLC/pm-tpe/wiki/Feature-flags
 * Example:
 *
 *  <Split off={contentWhenNotInStaging}>
 *    {contentWhenInStaging}
 *  </Split>
 *
 **/

const Split = ({ flag = 'staging', children = null, off = null }) => {
  return <>{check(flag) ? children : off}</>
}

Split.propTypes = {
  flag: PropTypes.string,
  children: PropTypes.node,
  off: PropTypes.node,
}

export default Split
