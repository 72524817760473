// Stackdriver experimental error reporting
import StackdriverErrorReporter from 'stackdriver-errors-js'

export const errorHandler = new StackdriverErrorReporter()
errorHandler.start({
  key: 'AIzaSyDvBHYyABjc8sP4GQmRyViLOfAlg8cXst4',
  projectId: 'ferrous-agency-706',
  version: process.env.REACT_APP_GIT_SHA || 'unknown',
  disabled: process.env.NODE_ENV !== 'production',
})
