const config = {
  API: '/api',
  domain: 'auth0.app.theprospectexchange.com',
  clientID: 'FJFkfkenZjXaIgOUGaEyTTsxCfvRZXlm',
  redirectUri: `${window.location.origin}/auth/callback`,
  legacyTpeUrl:
    process.env.REACT_APP_LEGACY_TPE_URL ||
    'https://app.theprospectexchange.com',
  googleAnalyticsId: 'UA-143246194-1',
  redirectNonIcebergPlayers:
    process.env.NODE_ENV === 'production'
      ? 'https://app.theprospectexchange.com'
      : null,
  stripeApiKeys: {
    test: 'pk_test_HqCRm3uslpWWmg0BN0GuEDxt00nomBFcyx',
    production: 'pk_live_LXoIkV4iCv1864DZyfNzBouA000HHAGQEu',
  },
}

export default config
