import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import windowSize from 'src/hoc/windowSize'
import Split from 'src/components/Split'
import {
  Paper,
  Grid,
  ButtonBase,
  Popper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Grow,
  Link,
} from '@mui/material'
import Shown from 'src/components/Shown'
import Logo from 'src/components/Logo'
import SearchBox from 'src/components/SearchBox'
import facebookLogo from 'src/assets/img/facebook-logo.png'
import twitterLogo from 'src/assets/img/twitter-logo.png'
import { ReactComponent as YourDashboardSvg } from 'src/assets/img/buttonIcons/yourDashboard.svg'
import { ReactComponent as ViewedPlayersSvg } from 'src/assets/img/buttonIcons/viewedPlayers.svg'
import { ReactComponent as PowerOffSvg } from 'src/assets/img/buttonIcons/powerOff.svg'
import { ReactComponent as CreditsSvg } from 'src/assets/img/buttonIcons/credits.svg'
import { ReactComponent as CoachContactSvg } from 'src/assets/img/buttonIcons/coachContact.svg'
import { ReactComponent as UploadSvg } from 'src/assets/img/buttonIcons/upload.svg'
import { ReactComponent as EnvelopeSvg } from 'src/assets/img/buttonIcons/envelope.svg'
import { ReactComponent as FaqSvg } from 'src/assets/img/buttonIcons/faq.svg'
import { ReactComponent as PrivacySvg } from 'src/assets/img/buttonIcons/privacy.svg'
import { ReactComponent as TermsSvg } from 'src/assets/img/buttonIcons/terms.svg'
import { ReactComponent as EditSvg } from 'src/assets/img/buttonIcons/edit.svg'
import Delete from '@mui/icons-material/Delete'
import { MainText } from 'src/assets/theme/text'
import StopScroll from 'src/components/StopScroll'

import { canCommunicate } from 'src/services/Entitlements'
import { api } from 'src/components/App'
import { Creators } from 'src/redux/actions'
import { pipe } from 'src/utils/pipe'
import {
  HeaderContainer,
  ButtonGroup,
  HamburgerBtn,
  MenuListContainer,
  MobileMenu,
  PlayerMenu,
  CoachMenu,
  MobileMenuLink,
  LogOutBtn,
  SocialContainer,
  SvgWrapper,
} from './styles'
import UserLoginOrSignUp from './UserLoginOrSignUp'

class Header extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    profile: PropTypes.object,
    auth: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    matchesMediaQuery: PropTypes.bool,
    logout: PropTypes.func,
    noRoutesToOtherPages: PropTypes.bool,
  }

  state = {
    popperId: 'profile-popper',
    isProfileMenuOpen: false,
    anchorEl: null,
    playerName: '',
  }

  componentDidUpdate({ matchesMediaQuery: prevMatchesQuery }) {
    const { matchesMediaQuery } = this.props

    if (matchesMediaQuery !== prevMatchesQuery) {
      this.setState({ isProfileMenuOpen: false })
    }
  }

  handleSignUpClick = () => {
    const { history } = this.props
    history.push('/auth/sign-up')
  }

  handleLoginClick = () => {
    const { history } = this.props
    history.push('/auth/login')
  }

  handleProfileMenuToggle = () => {
    window.scrollTo(0, 0)
    this.setState((state) => ({
      isProfileMenuOpen: !state.isProfileMenuOpen,
    }))
  }

  handleProfileMenuClose = (event) => {
    const { anchorEl } = this.state
    const { target } = event

    if (anchorEl.contains(target)) {
      return
    }

    this.setState({ isProfileMenuOpen: false })
  }

  handleLogoutClick = (event) => {
    this.props.logout()
    this.handleProfileMenuClose(event)
  }

  handlePlayerName = (e) => {
    const playerName = e.target.value
    this.setState({ playerName })
  }

  handlePlayerSearch = () => {
    const { playerName } = this.state
    const { history } = this.props
    if (playerName.length > 0) {
      const currentLocation = this.props.location.pathname
      history.push(`/dashboard-coach?q=${playerName}`)
      if (currentLocation === '/dashboard-coach') {
        location.reload()
      } else {
        this.setState({
          isProfileMenuOpen: false,
        })
      }
    }
  }

  render() {
    if (this.props.noRoutesToOtherPages) {
      return (
        <Paper
          elevation={1}
          square
          style={{ position: 'relative', zIndex: 1300 }}
        >
          <HeaderContainer>
            <Grid container alignItems="center">
              <Grid item xs={4}>
                <Logo noRoutesToOtherPages />
              </Grid>
            </Grid>
          </HeaderContainer>
        </Paper>
      )
    }
    const { isProfileMenuOpen, anchorEl, playerName } = this.state
    const {
      history,
      user,
      profile,
      auth: { isAuthenticated },
    } = this.props

    return (
      <Paper
        elevation={1}
        square
        style={{ position: 'relative', zIndex: 1300 }}
      >
        <HeaderContainer>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Logo isAuthenticated={isAuthenticated} />
            </Grid>

            <Grid
              item
              xs={8}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {isAuthenticated && (
                <ButtonGroup>
                  <ButtonBase
                    aria-owns={isProfileMenuOpen ? 'profile-menu-grow' : null}
                    ref={(anchorEl) => {
                      this.setState({ anchorEl })
                    }}
                    style={{ borderRadius: '50%' }}
                    onClick={this.handleProfileMenuToggle}
                  >
                    <HamburgerBtn>
                      <span />
                      <span />
                      <span />
                    </HamburgerBtn>
                  </ButtonBase>

                  {this.props.matchesMediaQuery && (
                    // Only mount the popper in the correct viewports
                    <Popper
                      transition
                      disablePortal
                      open={isProfileMenuOpen}
                      anchorEl={anchorEl}
                      placement="bottom-end"
                      style={{ zIndex: 3000 }}
                    >
                      {({ TransitionProps }) => (
                        <Grow
                          {...TransitionProps}
                          id="profile-menu-grow"
                          style={{ transformOrigin: 'bottom' }}
                        >
                          <Paper>
                            <ClickAwayListener
                              onClickAway={this.handleProfileMenuClose}
                            >
                              <MenuListContainer>
                                <MenuList className="leftColumn">
                                  <MenuItem
                                    style={{
                                      display: user.userType ? 'flex' : 'none',
                                    }}
                                    onClick={() => {
                                      history.push(
                                        user.userType === 'COACH'
                                          ? '/dashboard-coach'
                                          : '/dashboard-player'
                                      )
                                    }}
                                  >
                                    <SvgWrapper>
                                      <YourDashboardSvg />
                                    </SvgWrapper>
                                    My Dashboard
                                  </MenuItem>
                                  {user.userType === 'PLAYER' && (
                                    <>
                                      <MenuItem
                                        onClick={() => {
                                          history.push('/purchase-credits')
                                        }}
                                      >
                                        <SvgWrapper>
                                          <CreditsSvg />
                                        </SvgWrapper>
                                        Buy Credits
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          history.push('/new-game')
                                        }}
                                      >
                                        <SvgWrapper>
                                          <UploadSvg />
                                        </SvgWrapper>
                                        Game Upload
                                      </MenuItem>
                                      {canCommunicate(user, profile) && (
                                        <MenuItem
                                          onClick={() => {
                                            history.push('/send-to-coach')
                                          }}
                                        >
                                          <SvgWrapper>
                                            <CoachContactSvg />
                                          </SvgWrapper>
                                          Coach Contact
                                        </MenuItem>
                                      )}
                                    </>
                                  )}

                                  <MenuItem
                                    style={{
                                      display: user.userType ? 'flex' : 'none',
                                    }}
                                    onClick={() => {
                                      history.push(
                                        user.userType === 'COACH'
                                          ? '/profile-views-coach'
                                          : '/profile-views-player'
                                      )
                                    }}
                                  >
                                    <SvgWrapper>
                                      <ViewedPlayersSvg />
                                    </SvgWrapper>
                                    {user.userType === 'COACH'
                                      ? 'Viewed Players'
                                      : 'Profile Views'}
                                  </MenuItem>

                                  {user.userType === 'PLAYER' && (
                                    <MenuItem
                                      onClick={() => {
                                        history.push('/profile/edit-profile')
                                      }}
                                    >
                                      <SvgWrapper>
                                        <EditSvg />
                                      </SvgWrapper>
                                      Edit Profile
                                    </MenuItem>
                                  )}
                                  <MenuItem onClick={this.handleLogoutClick}>
                                    <SvgWrapper>
                                      <PowerOffSvg />
                                    </SvgWrapper>
                                    Logout
                                  </MenuItem>
                                  <Split>
                                    <MenuItem
                                      onClick={async (e) => {
                                        e.persist()
                                        if (
                                          confirm(
                                            'Are you sure you want to delete your account?'
                                          )
                                        ) {
                                          const { ok, data } =
                                            await api.deleteUser()
                                          if (!ok) {
                                            alert(
                                              'Could not delete your account. Please try again later.\n\n(Error: ' +
                                                (data.message || '[unknown]') +
                                                ')'
                                            )
                                            console.error(e)
                                            return
                                          }
                                          this.handleLogoutClick(e)
                                          window.location = '/'
                                        }
                                      }}
                                    >
                                      <SvgWrapper>
                                        <Delete />
                                      </SvgWrapper>
                                      Delete Account
                                    </MenuItem>
                                  </Split>
                                </MenuList>
                                <MenuList>
                                  <MenuItem
                                    onClick={() => {
                                      window.location.assign(
                                        'https://theprospectexchange.com/faq/'
                                      )
                                    }}
                                  >
                                    <SvgWrapper>
                                      <FaqSvg />
                                    </SvgWrapper>
                                    FAQ
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      history.push('/terms-and-conditions')
                                    }}
                                  >
                                    <SvgWrapper>
                                      <TermsSvg />
                                    </SvgWrapper>
                                    Terms
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      history.push('/privacy-policy')
                                    }}
                                  >
                                    <SvgWrapper>
                                      <PrivacySvg />
                                    </SvgWrapper>
                                    Privacy
                                  </MenuItem>
                                  <MenuItem>
                                    <SvgWrapper>
                                      <EnvelopeSvg />
                                    </SvgWrapper>
                                    <Link
                                      underline="none"
                                      href="mailto:info@theprospectexchange.com"
                                    >
                                      <MainText>Contact Us</MainText>
                                    </Link>
                                  </MenuItem>
                                </MenuList>
                              </MenuListContainer>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  )}
                </ButtonGroup>
              )}

              {!isAuthenticated && (
                <UserLoginOrSignUp
                  onLoginClick={this.handleLoginClick}
                  onSignUpClick={this.handleSignUpClick}
                />
              )}
            </Grid>
          </Grid>
        </HeaderContainer>

        <Shown untilSm>
          <StopScroll stopScroll={isProfileMenuOpen} />
          {isAuthenticated && user.userType ? (
            <MobileMenu isOpen={isProfileMenuOpen}>
              <PlayerMenu isPlayer={user.userType === 'PLAYER'}>
                <MobileMenuLink
                  onClick={() => {
                    history.push('/dashboard-player')
                    this.setState({ isProfileMenuOpen: false })
                  }}
                >
                  My Dashboard
                </MobileMenuLink>
                <MobileMenuLink
                  onClick={() => {
                    history.push('/purchase-credits')
                  }}
                >
                  Buy Credits
                </MobileMenuLink>
                <MobileMenuLink
                  onClick={() => {
                    history.push('/new-game')
                  }}
                >
                  Game Upload
                </MobileMenuLink>
                {canCommunicate(user, profile) && (
                  <MobileMenuLink
                    onClick={() => history.push('/send-to-coach')}
                  >
                    Coach Contact
                  </MobileMenuLink>
                )}
                <MobileMenuLink
                  onClick={() => history.push('/profile-views-player')}
                >
                  Profile Views
                </MobileMenuLink>
                <MobileMenuLink
                  onClick={() => history.push('/profile/edit-profile')}
                >
                  Edit Profile
                </MobileMenuLink>
                <div hidden>
                  <MobileMenuLink>Share Profile</MobileMenuLink>
                  <SocialContainer>
                    <img src={facebookLogo} />
                    <img src={twitterLogo} />
                  </SocialContainer>
                </div>
                <LogOutBtn onClick={this.handleLogoutClick}>Log Out</LogOutBtn>
              </PlayerMenu>
              <CoachMenu isCoach={user.userType === 'COACH'}>
                <SearchBox
                  darkMode
                  placeholderText="Player's name"
                  value={playerName}
                  onChange={(e) => this.handlePlayerName(e)}
                  onSearch={this.handlePlayerSearch}
                />
                <MobileMenuLink
                  onClick={() => {
                    history.push('/dashboard-coach')
                    this.setState({ isProfileMenuOpen: false })
                  }}
                >
                  My Dashboard
                </MobileMenuLink>
                <MobileMenuLink
                  onClick={() => history.push('/profile-views-coach')}
                >
                  Viewed Players
                </MobileMenuLink>
                <LogOutBtn onClick={this.handleLogoutClick}>Log Out</LogOutBtn>
              </CoachMenu>
            </MobileMenu>
          ) : (
            <MobileMenu isOpen={isProfileMenuOpen}>
              <LogOutBtn hasNoProfile onClick={this.handleLogoutClick}>
                Log Out
              </LogOutBtn>
            </MobileMenu>
          )}
        </Shown>
      </Paper>
    )
  }
}

const mapStateToProps = ({ user, auth }) => ({
  user: user.user,
  profile: user.profile,
  auth,
})

const mapDispatchToProps = {
  logout: Creators.logout,
}

export default pipe(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  windowSize('(min-width: 650px) and (max-width:1279px)', true)
)(Header)
