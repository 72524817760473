import React from 'react'
import styled from 'styled-components/macro'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'

import { canUseWebsite } from 'src/services/Entitlements'
import Header from 'src/components/Header'
import { Page } from 'src/assets/theme/containers'
import Loading from 'src/components/Loading'
import FrameWithSideBar from 'src/components/FrameWithSideBar'

const CenterWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const selectWhetherHasProfile = ({ user: { user, profile } }) => {
  return Boolean(user?.userType && profile && canUseWebsite(user, profile))
}

/**
 * Displayed while React.lazy loads code (or when we start using suspense elsewhere)
 */
const GlobalSuspenseFallback = () => {
  const { pathname } = useLocation()
  const hasUsableProfile = useSelector(selectWhetherHasProfile)

  if (pathname.startsWith('/auth') || !hasUsableProfile) {
    return (
      <Page>
        <Header />
        <CenterWrapper>
          <Loading />
        </CenterWrapper>
      </Page>
    )
  } else {
    return (
      <FrameWithSideBar>
        <Loading />
      </FrameWithSideBar>
    )
  }
}

export default GlobalSuspenseFallback
