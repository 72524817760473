import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Redirect, Route } from 'react-router-dom'
import { pipe } from 'src/utils/pipe'
import { LocalStorage } from 'src/services/LocalStorage'

const getNextParameter = (location) =>
  new URLSearchParams(location.search).get('next') || ''

export function addNextParameter(currentLocation, nextLocation) {
  if (getNextParameter(nextLocation)) {
    return currentLocation
  }
  let nextUrl = nextLocation.pathname
  if (nextLocation.search) {
    nextUrl += escape(nextLocation.search)
  }
  return currentLocation + '?next=' + nextUrl
}

const getDashboardURL = (user) =>
  user.userType === 'COACH' ? '/dashboard-coach' : '/dashboard-player'

/** Bounce a user whose e-mail is verified */
export const bounceVerifiedUser = ({
  user,
  profile,
  routeUserType,
  currentLocation = {},
}) => {
  if (currentLocation.pathname === '/auth/email/confirm') {
    return getDashboardURL(user)
  }

  if (
    currentLocation.pathname === '/auth/login' &&
    getNextParameter(currentLocation)
  ) {
    // Happens when the user logs in while there is a next parameter,
    // then clicks back
    return getNextParameter(currentLocation)
  }

  if (user.userType != null && routeUserType === 'NONE') {
    return getDashboardURL(user)
  }

  if (profile == null) {
    return '/profile/create-profile'
  }

  const hasTeam = profile.teamId !== 10000 && profile.teamId != null
  if (!hasTeam) {
    return '/profile/wait-for-requested-team'
  }

  if (
    currentLocation.pathname === '/profile/wait-for-requested-team' &&
    hasTeam
  ) {
    return getDashboardURL(user)
  }

  const userTypeNotAsDesired =
    user.userType &&
    routeUserType != null &&
    routeUserType !== 'NONE' &&
    user.userType !== routeUserType

  if (userTypeNotAsDesired) {
    return getDashboardURL(user)
  }

  // One-off redirect. This is stored in localStorage because e-mail confirmation takes time
  const marketingSiteRedirect = LocalStorage.getItem('marketingSiteRedirect')
  if (marketingSiteRedirect) {
    const atDashboard =
      /\/players\/\d+$/.test(currentLocation.pathname) ||
      currentLocation.pathname.startsWith('/dashboard')
    const atRedirectDestination = marketingSiteRedirect.startsWith(
      currentLocation.pathname
    )

    // When we got to the marketing redirect URL, delete it
    if (atRedirectDestination || profile.subscriptionStatus === 'ACTIVE') {
      LocalStorage.removeItem('marketingSiteRedirect')
      return
    }

    // If we're at the dashboard, go to the marketing site
    if (atDashboard) {
      return marketingSiteRedirect
    }
  }
}

export const bounceUnverifiedUser = ({
  currentLocation,
  protection,
  isAuthenticated,
  user,
}) => {
  // bounce user to /auth/login
  if (protection !== 'none' && !isAuthenticated) {
    if (!/^\/auth\//.test(currentLocation.pathname)) {
      return addNextParameter('/auth/login', currentLocation)
    }
    return '/auth/login'
  }

  if (protection === 'verified') {
    return '/auth/email/confirm'
  }

  if (protection === 'none') {
    return null
  }

  if (
    isAuthenticated &&
    ['/auth/login', '/auth/sign-up'].includes(currentLocation.pathname)
  ) {
    // Corner case where the user is already logged in, but lands on login and sign up pages
    // Happens when the user is logged in and presses back on their browser.
    if (user.userType === 'COACH') {
      return '/dashboard-coach'
    }
    return '/dashboard-player'
  }
}

/** Use this component to render protected routes
 *
 *  Protection types: "authenticated", "verified", "none"
 *
 *  If protection type is set to "authenticated" the user will be able to navigate
 *  to pages that require them to be signed in, but doesn't require them to have his
 *  email verified
 *
 *  If protection type is set to "verified" the user will only be allowed to navigate
 *  to the page if he has a verified email address
 *
 *  The protection type "none" means that any user can access the route.
 */
const AuthorizedRoute = (authorizedRouteProps) => {
  const {
    history,
    location,
    protection,
    component: Component,
    render,
    user,
    auth,
    entitlement,
    profile,
    skipBounce = () => false,
    userType: routeUserType,
    ...propsForRoute
  } = authorizedRouteProps
  const redirectOrRender = (props) => {
    const { isVerified, isAuthenticated } = auth
    const currentLocation = props.location

    if (!skipBounce(authorizedRouteProps)) {
      const redirectTo = isVerified
        ? bounceVerifiedUser({
            currentLocation,
            user,
            profile,
            routeUserType,
          })
        : bounceUnverifiedUser({
            currentLocation,
            protection,
            isAuthenticated,
            user,
          })

      if (redirectTo && redirectTo !== currentLocation.pathname) {
        return <Redirect to={redirectTo} />
      }

      if (
        entitlement &&
        user?.userType &&
        !['/dashboard-player', '/dashboard-coach'].includes(
          currentLocation.pathname
        ) &&
        !entitlement(user, profile)
      ) {
        return <Redirect to={getDashboardURL(user)} />
      }
    }

    if (render) {
      return render(props)
    } else if (Component) {
      return <Component {...props} />
    } else {
      throw new Error(
        'AuthorizedRoute: either `render` or `component` prop must be passed.'
      )
    }
  }

  return <Route {...propsForRoute} render={redirectOrRender} />
}

AuthorizedRoute.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  render: PropTypes.func,
  protection: PropTypes.oneOf(['none', 'authenticated', 'verified']).isRequired,
  entitlement: PropTypes.func,
  user: PropTypes.object,
  auth: PropTypes.object,
  profile: PropTypes.object,
  userType: PropTypes.string,
}

const mapStateToProps = ({ user, auth }) => ({
  profile: user.profile,
  user: user.user,
  auth,
})

export default pipe(connect(mapStateToProps), withRouter)(AuthorizedRoute)
